export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any
}

export type _Service = {
  __typename?: '_Service'
  sdl?: Maybe<Scalars['String']>
}

/**
 * This is what will be returned to the frontend so it can determine
 * upsell opportunities and discounts to display.
 */
export type AccountDiscounts = {
  __typename?: 'AccountDiscounts'
  enterpriseDiscount?: Maybe<Discount>
  freeDiscount?: Maybe<Discount>
  proDiscount?: Maybe<Discount>
  proflexDiscount?: Maybe<Discount>
  proplusDiscount?: Maybe<Discount>
}

export type AnalyticsDayType = {
  __typename?: 'AnalyticsDayType'
  day: Scalars['Date']
  scans: Scalars['Int']
  users: Scalars['Int']
}

export type AnalyticsTotalType = {
  __typename?: 'AnalyticsTotalType'
  avgScans: Scalars['Float']
  avgUsers: Scalars['Float']
  scans: Scalars['Int']
  scansToday: Scalars['Int']
  users: Scalars['Int']
}

export type AnalyticsType = {
  __typename?: 'AnalyticsType'
  days?: Maybe<Array<Maybe<AnalyticsDayType>>>
  lastScanDate?: Maybe<Scalars['Date']>
  total?: Maybe<AnalyticsTotalType>
}

export type AnalyticsTypeDaysArgs = {
  endDay?: InputMaybe<Scalars['Date']>
  numDays?: InputMaybe<Scalars['Int']>
}

export type ApiClientCampaignType = Node & {
  __typename?: 'ApiClientCampaignType'
  /** The ID of the object. */
  id: Scalars['ID']
  lastModified: Scalars['DateTime']
  name: Scalars['String']
  numFlowcodes?: Maybe<Scalars['Int']>
  timeStamp: Scalars['DateTime']
}

export type ApiClientCampaignTypeConnection = {
  __typename?: 'ApiClientCampaignTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApiClientCampaignTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ApiClientCampaignType` and its cursor. */
export type ApiClientCampaignTypeEdge = {
  __typename?: 'ApiClientCampaignTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ApiClientCampaignType>
}

/**
 * ArchiveBatch is called when the user chooses to archive a code.
 * The archive_reason is set to USER_ARCHIVAL accordingly.
 */
export type ArchiveBatch = {
  __typename?: 'ArchiveBatch'
  ok?: Maybe<Scalars['Boolean']>
}

/** An enumeration. */
export enum AssetFileAssetType {
  /** Center Image */
  CenterImage = 'CENTER_IMAGE',
  /** Container */
  Container = 'CONTAINER',
  /** External Center Image */
  ExternalCenterImage = 'EXTERNAL_CENTER_IMAGE',
  /** Eyes */
  Eyes = 'EYES',
  /** File */
  File = 'FILE',
  /** Gif */
  Gif = 'GIF',
  /** Icon */
  Icon = 'ICON',
  /** Pattern */
  Pattern = 'PATTERN',
  /** Print */
  Print = 'PRINT',
  /** Rim */
  Rim = 'RIM'
}

export type AssetFileObjectType = Node & {
  __typename?: 'AssetFileObjectType'
  assetName: Scalars['String']
  assetType: AssetFileAssetType
  batch?: Maybe<BatchDjangoType>
  /** The ID of the object. */
  id: Scalars['ID']
  tags: Array<StudioTagObjectType>
  url?: Maybe<Scalars['String']>
}

export type AssetFileObjectTypeConnection = {
  __typename?: 'AssetFileObjectTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetFileObjectTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `AssetFileObjectType` and its cursor. */
export type AssetFileObjectTypeEdge = {
  __typename?: 'AssetFileObjectTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AssetFileObjectType>
}

export type AssetLabelType = Node & {
  __typename?: 'AssetLabelType'
  batch?: Maybe<BatchDjangoType>
  /** The ID of the object. */
  id: Scalars['ID']
  isRecommended: Scalars['Boolean']
  name: Scalars['String']
}

export type AssetLabelTypeConnection = {
  __typename?: 'AssetLabelTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetLabelTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `AssetLabelType` and its cursor. */
export type AssetLabelTypeEdge = {
  __typename?: 'AssetLabelTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AssetLabelType>
}

/**
 * AssignStudioConfig gives a user the ability to use the StudioConfig
 * i.e. used by the FE to give user access to a code design after signing up
 */
export type AssignStudioConfig = {
  __typename?: 'AssignStudioConfig'
  ok?: Maybe<Scalars['Boolean']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

export type AssociateProject = {
  __typename?: 'AssociateProject'
  dhProjectId: Scalars['String']
  ok: Scalars['Boolean']
  projectId: Scalars['UUID']
}

export type AutoDesignObjectType = {
  __typename?: 'AutoDesignObjectType'
  configuration?: Maybe<Scalars['JSONString']>
  ids?: Maybe<Array<Maybe<Scalars['UUID']>>>
}

export type BatchConnection = {
  __typename?: 'BatchConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BatchEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum BatchCreationSource {
  /** Api */
  Api = 'API',
  /** Flowtag */
  Flowtag = 'FLOWTAG',
  /** Starter Kit */
  StarterKit = 'STARTER_KIT',
  /** Video Tool */
  VideoTool = 'VIDEO_TOOL'
}

export type BatchDesignTemplateInputType = {
  configuration?: InputMaybe<Scalars['JSONString']>
  name?: InputMaybe<Scalars['String']>
}

export type BatchDesignTemplateObjectType = Node & {
  __typename?: 'BatchDesignTemplateObjectType'
  configuration: Scalars['JSONString']
  /** The ID of the object. */
  id: Scalars['ID']
  lastModified: Scalars['DateTime']
  name: Scalars['String']
  owner: UserType
  timeStamp: Scalars['DateTime']
}

export type BatchDesignTemplateObjectTypeConnection = {
  __typename?: 'BatchDesignTemplateObjectTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BatchDesignTemplateObjectTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BatchDesignTemplateObjectType` and its cursor. */
export type BatchDesignTemplateObjectTypeEdge = {
  __typename?: 'BatchDesignTemplateObjectTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BatchDesignTemplateObjectType>
}

export type BatchDjangoType = Node & {
  __typename?: 'BatchDjangoType'
  activeLink?: Maybe<LinkDjangoType>
  analytics?: Maybe<AnalyticsType>
  archived: Scalars['Boolean']
  assetFile?: Maybe<AssetFileObjectType>
  assetFileName?: Maybe<Scalars['String']>
  assetLabels?: Maybe<Array<Maybe<AssetLabelType>>>
  batchOwner?: Maybe<BatchOwnerDjangoType>
  batchRules?: Maybe<RuleBasedObjectType>
  creationSource?: Maybe<BatchCreationSource>
  directory?: Maybe<DirectoryType>
  domain: Scalars['String']
  entityPermissions?: Maybe<Array<Maybe<EntityPermissionObjectType>>>
  /** The ID of the object. */
  id: Scalars['ID']
  lastModified: Scalars['DateTime']
  nickname: Scalars['String']
  studioConfig?: Maybe<StudioConfigObjectType>
  timeStamp: Scalars['DateTime']
  type: BatchType
}

export type BatchDjangoTypeConnection = {
  __typename?: 'BatchDjangoTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BatchDjangoTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BatchDjangoType` and its cursor. */
export type BatchDjangoTypeEdge = {
  __typename?: 'BatchDjangoTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BatchDjangoType>
}

/** A Relay edge containing a `Batch` and its cursor. */
export type BatchEdge = {
  __typename?: 'BatchEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BatchDjangoType>
}

export type BatchInputType = {
  nickname?: InputMaybe<Scalars['String']>
  redirectDomain?: InputMaybe<Scalars['String']>
  studioConfigId?: InputMaybe<Scalars['UUID']>
}

export type BatchOwnerDjangoType = {
  __typename?: 'BatchOwnerDjangoType'
  anonymousSessionId?: Maybe<Scalars['String']>
  batch: BatchDjangoType
  isOwner?: Maybe<Scalars['Boolean']>
  user?: Maybe<UserType>
}

/** An enumeration. */
export enum BatchType {
  /** many */
  Many = 'MANY',
  /** one */
  One = 'ONE',
  /** static_code */
  StaticCode = 'STATIC_CODE'
}

export type BorderDesignObjectType = {
  __typename?: 'BorderDesignObjectType'
  partialId?: Maybe<Scalars['UUID']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

export type BrandKit = {
  __typename?: 'BrandKit'
  flowcodeImage: Scalars['String']
  logoImage: Scalars['String']
  primaryColor: Scalars['String']
  primaryText: Scalars['String']
  secondaryColor: Scalars['String']
}

export type BrandKitInput = {
  logoImage?: InputMaybe<Scalars['String']>
  primaryColor?: InputMaybe<Scalars['String']>
  primaryText?: InputMaybe<Scalars['String']>
  secondaryColor?: InputMaybe<Scalars['String']>
}

/**
 * BulkArchiveBatches is called when the user chooses to archive a list of codes.
 * The archive_reason is set to USER_ARCHIVAL accordingly.
 */
export type BulkArchiveBatches = {
  __typename?: 'BulkArchiveBatches'
  ok?: Maybe<Scalars['Boolean']>
}

/** BulkDeleteDirectories deletes a list of directories and all the batches within it */
export type BulkDeleteDirectories = {
  __typename?: 'BulkDeleteDirectories'
  ok?: Maybe<Scalars['Boolean']>
}

/** BulkRestoreBatches is the reverse of BulkArchiveBatches and restores a list of codes to be active again. */
export type BulkRestoreBatches = {
  __typename?: 'BulkRestoreBatches'
  ok?: Maybe<Scalars['Boolean']>
}

/** BulkUpdateBatchDirectory changes or removes the directory a list of batches are in */
export type BulkUpdateBatchDirectory = {
  __typename?: 'BulkUpdateBatchDirectory'
  batches?: Maybe<Array<Maybe<BatchDjangoType>>>
  ok?: Maybe<Scalars['Boolean']>
}

/** BulkUpdateDirectories moves a list of directories in and out of other directories */
export type BulkUpdateDirectories = {
  __typename?: 'BulkUpdateDirectories'
  ok?: Maybe<Scalars['Boolean']>
}

export enum CanadiensMode {
  HockeyMode = 'HOCKEY_MODE',
  ShowMode = 'SHOW_MODE'
}

export type Categories = {
  __typename?: 'Categories'
  categories?: Maybe<Array<Category>>
  pageInfo: PageInfo
}

export type Category = {
  __typename?: 'Category'
  categoryId: Scalars['UUID']
  categoryName: Scalars['String']
  sectionName: Scalars['String']
}

/** This is what will be returned to the frontend when requesting category product mappings. */
export type CategoryProductMappingsResponse = {
  __typename?: 'CategoryProductMappingsResponse'
  categoryProductMappings?: Maybe<Array<Maybe<ProductCategoryWithProducts>>>
  paginationInfo?: Maybe<PaginationInfo>
}

export type CheckoutCartItemInput = {
  batchId: Scalars['String']
  cartItemId?: InputMaybe<Scalars['String']>
  dhProjectExportId?: InputMaybe<Scalars['String']>
  dhProjectId?: InputMaybe<Scalars['String']>
  dhTemplateId?: InputMaybe<Scalars['String']>
  priceId: Scalars['String']
  productId: Scalars['String']
  quantity: Scalars['Int']
  templateDetailsJson?: InputMaybe<Scalars['String']>
  templateId?: InputMaybe<Scalars['String']>
  unitAmount: Scalars['Int']
}

/** This is what the frontend needs to pass on customer checkout. */
export type CheckoutInput = {
  businessName?: InputMaybe<Scalars['String']>
  cartId?: InputMaybe<Scalars['String']>
  cartItems: Array<CheckoutCartItemInput>
  cartItemsSubtotal?: InputMaybe<Scalars['Int']>
  cartItemsSubtotalDiscounted?: InputMaybe<Scalars['Int']>
  countryCode: Scalars['String']
  currency: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  errorUrl: Scalars['String']
  handlingOptionId?: InputMaybe<Scalars['String']>
  shippingAndHandlingSubtotal?: InputMaybe<Scalars['Int']>
  shippingOptionId: Scalars['String']
  source?: InputMaybe<CheckoutSourceType>
  successUrl: Scalars['String']
  useDynamicShipping?: InputMaybe<Scalars['Boolean']>
}

export enum CheckoutSourceType {
  FlowcodeStore = 'FLOWCODE_STORE',
  StarterKit = 'STARTER_KIT'
}

export type CodeManagementType = {
  __typename?: 'CodeManagementType'
  allBatches?: Maybe<BatchConnection>
  highlighted?: Maybe<BatchConnection>
}

export type CodeManagementTypeAllBatchesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type CodeManagementTypeHighlightedArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/**
 * CollectBatch is used with scan to own codes and creates a BatchOwner for a Batch
 * Switches `is_collectible` to false as scan to own codes are one-time use only.
 *
 * -   CollectBatch will grant the user access to the studio_config design if they do not have it already
 * -   Note: there is no check against ithaca, however that is not a concern since users are net-new.
 *     Worth keeping a pulse on whether or not users of STO have accounts in which the same studio_config could be
 *     assigned to a user
 * -   claimed_page_id is the page_id of the slugName that is getting claimed in the preset onboarding flow. If
 *     we aren't in the preset onboarding flow then claimed_page_id should be None
 */
export type CollectBatch = {
  __typename?: 'CollectBatch'
  batchOwner?: Maybe<BatchOwnerDjangoType>
  claimedPageId?: Maybe<Scalars['ID']>
  ok?: Maybe<Scalars['Boolean']>
}

export type CollectBatchCodeInputType = {
  nickname?: InputMaybe<Scalars['String']>
  redirectType: RedirectType
  redirectValue: Scalars['String']
}

export type CollectibleResponseType = {
  __typename?: 'CollectibleResponseType'
  isCollectible?: Maybe<Scalars['Boolean']>
  redirectType?: Maybe<RedirectType>
  redirectValue?: Maybe<Scalars['String']>
}

export enum CombinedGeneratorCampaigns {
  OptiCombinedGenerator_1 = 'OPTI_COMBINED_GENERATOR_1',
  OptiCombinedGenerator_2 = 'OPTI_COMBINED_GENERATOR_2',
  OptiCombinedGenerator_3 = 'OPTI_COMBINED_GENERATOR_3',
  OptiCombinedGenerator_4 = 'OPTI_COMBINED_GENERATOR_4'
}

/** CreateAssetLabels creates asset labels for the specified batch id. */
export type CreateAssetLabels = {
  __typename?: 'CreateAssetLabels'
  assetLabels?: Maybe<Array<Maybe<AssetLabelType>>>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * CreateBatch creates a batch, link, batch_owner, and redirect object
 * It also creates entries in the batch_partial table if partial ids are supplied
 */
export type CreateBatch = {
  __typename?: 'CreateBatch'
  batch?: Maybe<BatchDjangoType>
  link?: Maybe<LinkDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * CreateBatchDesignTemplate creates a BatchDesignTemplate object for a user and configuration
 * BatchDesignTemplate's are saved code configurations that allow users to save custom designs they have made for reuse
 */
export type CreateBatchDesignTemplate = {
  __typename?: 'CreateBatchDesignTemplate'
  batchTemplate?: Maybe<BatchDesignTemplateObjectType>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * CreateBatchRules takes in a rule and/or an interstitial and merges them into a single config that determines how the batch is handled during a redirect.
 * It updates the CoreBatchRulesHistory table with an entry for each created object.
 */
export type CreateBatchRules = {
  __typename?: 'CreateBatchRules'
  batchRule?: Maybe<RuleBasedObjectType>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * CreateCollectibleBatch handles creating collectible flowcodes
 * All flowcodes created through this mutation are attached to our internal api_client_id and campaign
 *
 * - In the case that a batchId is passing in, we want to use that code to build a new code for the user.
 * - This code is not a collectible code but a copy of the one that was passed in
 *
 * - In the case that sendEmail is passed in, we send a notification with the new code for the user.
 */
export type CreateCollectibleBatch = {
  __typename?: 'CreateCollectibleBatch'
  batch?: Maybe<BatchDjangoType>
  link?: Maybe<LinkDjangoType>
}

/**
 * CreateDirectory creates a directory
 * Directories can be nested
 * if batch_ids are supplied the batches are updated to be in the directory
 */
export type CreateDirectory = {
  __typename?: 'CreateDirectory'
  directory?: Maybe<DirectoryType>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * CreateExternalDomain creates an external domain for a user/org who owns their domain and
 * brings it into the Flowcode ecosystem, and assigns it to that user/org. For example,
 * if BMW owns the domain https://bmw.com/ and wants to create redirect and/or page urls
 * using this domain, this mutation creates the domain in the Domains Service and assigns
 * the BMW org as the owner of the domain.
 */
export type CreateExternalDomain = {
  __typename?: 'CreateExternalDomain'
  externalDomain?: Maybe<WhiteLabelRedirectDomainType>
  ok?: Maybe<Scalars['Boolean']>
}

/** CreatePlacementTemplateCategory creates either a child or root (parentless) category */
export type CreatePlacementTemplateCategory = {
  __typename?: 'CreatePlacementTemplateCategory'
  ok?: Maybe<Scalars['Boolean']>
  placementTemplateCategory?: Maybe<PlacementTemplateCategoryObjectType>
}

/** CreatePlacementTemplateConfig creates a placement template config with a placeholder asset file */
export type CreatePlacementTemplateConfig = {
  __typename?: 'CreatePlacementTemplateConfig'
  ok?: Maybe<Scalars['Boolean']>
  placementTemplateConfig?: Maybe<PlacementTemplateConfigObjectType>
}

/** CreatePlacementTemplateDownloadData records data for a placement template download/print event */
export type CreatePlacementTemplateDownloadData = {
  __typename?: 'CreatePlacementTemplateDownloadData'
  ok?: Maybe<Scalars['Boolean']>
}

export type CreateProjectExport = {
  __typename?: 'CreateProjectExport'
  ok: Scalars['Boolean']
  projectExportId: Scalars['UUID']
}

/**
 * CreateRedirectSubdomain creates a subdomain and assigns it to the requesting user,
 * creates entries in the WhiteLabelSubdomain and WhiteLabelSubdomainOwner tables in the domains service,
 * and creates an asset permission entry in ithaca for the user and subdomain.
 */
export type CreateRedirectSubdomain = {
  __typename?: 'CreateRedirectSubdomain'
  ok?: Maybe<Scalars['Boolean']>
  redirectSubdomain?: Maybe<WhiteLabelRedirectDomainType>
}

/**
 * CreateStudioConfig creates a StudioConfig and assigns source
 * Used for code creation and duplication
 * During custom code creation the FE will call this mutation then CreateBatch
 */
export type CreateStudioConfig = {
  __typename?: 'CreateStudioConfig'
  ok?: Maybe<Scalars['Boolean']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

/**
 * Used to differentiate behavior
 *         - null(default): created from the plaform
 *         - flowtag: the batch is used to create a flowtag
 *         - API: the batch was created from the enterprise API
 *
 */
export enum CreationSource {
  Api = 'API',
  Flowtag = 'FLOWTAG',
  StarterKit = 'STARTER_KIT',
  VideoTool = 'VIDEO_TOOL'
}

/** DeleteAssetLabels deletes asset labels for the specified asset label ids. */
export type DeleteAssetLabels = {
  __typename?: 'DeleteAssetLabels'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * DeleteBatchDesignTemplate deletes a BatchDesignTemplate object.
 * To be used from internal admin, and requires super admin access.
 */
export type DeleteBatchDesignTemplate = {
  __typename?: 'DeleteBatchDesignTemplate'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * DeleteBatchRules takes in should_delete_rule and should_delete_interstitial and removes the corresponding objects from the config.
 * If there is no remaining config after deletion, the entry will be deleted from the db.
 * Updates the CoreBatchRulesHistory table with an entry for each deleted object.
 */
export type DeleteBatchRules = {
  __typename?: 'DeleteBatchRules'
  ok?: Maybe<Scalars['Boolean']>
}

/** DeleteDirectory deletes a directory and all the batches within it */
export type DeleteDirectory = {
  __typename?: 'DeleteDirectory'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * DeleteExternalDomain soft-deletes an external domain. The operation will fail if the domain
 * has active/verified subdomains associated with it, or if the domain does not exist.
 */
export type DeleteExternalDomain = {
  __typename?: 'DeleteExternalDomain'
  ok?: Maybe<Scalars['Boolean']>
}

/** DeletePlacementTemplateCategory deletes a placement template */
export type DeletePlacementTemplateCategory = {
  __typename?: 'DeletePlacementTemplateCategory'
  ok?: Maybe<Scalars['Boolean']>
}

/** DeletePlacementTemplateConfig deletes a PlacementTemplateConfig */
export type DeletePlacementTemplateConfig = {
  __typename?: 'DeletePlacementTemplateConfig'
  ok?: Maybe<Scalars['Boolean']>
}

export type DeleteProject = {
  __typename?: 'DeleteProject'
  ok: Scalars['Boolean']
}

/**
 * Given subdomainId, soft deletes the associated subdomain from the domains service db and marks
 * the WhiteLabelRedirectDomain record as inactive, and deletes any shared permissions for the subdomain.
 */
export type DeleteRedirectSubdomain = {
  __typename?: 'DeleteRedirectSubdomain'
  ok?: Maybe<Scalars['Boolean']>
}

export type DirectoryConnection = {
  __typename?: 'DirectoryConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DirectoryEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type DirectoryDetailType = {
  __typename?: 'DirectoryDetailType'
  id?: Maybe<Scalars['Int']>
  isRoot?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
}

/** A Relay edge containing a `Directory` and its cursor. */
export type DirectoryEdge = {
  __typename?: 'DirectoryEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DirectoryType>
}

export type DirectoryManagementType = {
  __typename?: 'DirectoryManagementType'
  allDirs?: Maybe<DirectoryConnection>
}

export type DirectoryManagementTypeAllDirsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type DirectoryType = Node & {
  __typename?: 'DirectoryType'
  analytics?: Maybe<AnalyticsType>
  ancestors?: Maybe<Array<Maybe<DirectoryType>>>
  batchCount?: Maybe<Scalars['Int']>
  batchDirectorySet: BatchDjangoTypeConnection
  children?: Maybe<Array<Maybe<DirectoryType>>>
  descendantCount?: Maybe<Scalars['Int']>
  descendants?: Maybe<Array<Maybe<DirectoryType>>>
  description?: Maybe<Scalars['String']>
  entityPermissions?: Maybe<Array<Maybe<EntityPermissionObjectType>>>
  /** The ID of the object. */
  id: Scalars['ID']
  isOwner?: Maybe<Scalars['Boolean']>
  level: Scalars['Int']
  name: Scalars['String']
  parent?: Maybe<DirectoryType>
  timeStamp: Scalars['DateTime']
  user?: Maybe<UserType>
}

export type DirectoryTypeBatchDirectorySetArgs = {
  after?: InputMaybe<Scalars['String']>
  archived?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  creationSource?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  nickname_Icontains?: InputMaybe<Scalars['String']>
  nickname_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

/** Repesents a discount for the print store. */
export type Discount = {
  __typename?: 'Discount'
  percent?: Maybe<Scalars['Float']>
}

/**
 * EditBatchDesign creates a new StudioConfig and points the batch to it
 * This is used when switching to a non-premade design
 */
export type EditBatchDesign = {
  __typename?: 'EditBatchDesign'
  batch?: Maybe<BatchDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/** EditConfiguration alters the configuration column of a studio config */
export type EditConfiguration = {
  __typename?: 'EditConfiguration'
  ok?: Maybe<Scalars['Boolean']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

/**
 * EditStudioConfigRasterization sets the noRasterize option for a StudioConfig.
 * Any config, regardless of pubished status, can be updated.
 */
export type EditStudioConfigRasterization = {
  __typename?: 'EditStudioConfigRasterization'
  ok?: Maybe<Scalars['Boolean']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

/**
 * EmailBatch takes in the email_type parameter to determine which email to send out to the user.
 * - code_sent / code_sent_self: sends the flowcode to the user
 * - tag_referred: sends an email with a link to get a free flowtag
 *
 * :param: data - cannot contain keys: 'event_timestamp', 'batch_id' or 'to_email'
 */
export type EmailBatch = {
  __typename?: 'EmailBatch'
  ok?: Maybe<Scalars['Boolean']>
}

export enum EmailType {
  CodeSent = 'CODE_SENT',
  CodeSentSelf = 'CODE_SENT_SELF',
  CollectibleCodeRequested = 'COLLECTIBLE_CODE_REQUESTED',
  TagReferred = 'TAG_REFERRED'
}

export type EntityPermissionInputType = {
  entityId?: InputMaybe<Scalars['String']>
  entityType?: InputMaybe<Scalars['Int']>
  permissionName?: InputMaybe<Scalars['String']>
}

/**
 * Uses the same structure as the response object from ithaca to easily deconstruct resp into gql objectTypes
 * e.x GQLObjectType(**resp_data)
 */
export type EntityPermissionObjectType = {
  __typename?: 'EntityPermissionObjectType'
  entityId?: Maybe<Scalars['String']>
  entityName?: Maybe<Scalars['String']>
  entityType?: Maybe<Scalars['String']>
  permissionName?: Maybe<Scalars['String']>
}

/**
 *
 *     Supported Ithaca asset_permissions entity types
 *
 */
export enum EntityTypes {
  Org = 'ORG',
  Role = 'ROLE',
  Team = 'TEAM',
  User = 'USER'
}

/** Represents the date range (min and max) for the delivery date of a shopping cart. */
export type EstimatedDeliveryDateResponse = {
  __typename?: 'EstimatedDeliveryDateResponse'
  estimatedDeliveryDateMax?: Maybe<Scalars['DateTime']>
  estimatedDeliveryDateMin?: Maybe<Scalars['DateTime']>
}

export enum ExportFormat {
  Jpg = 'JPG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export enum ExportMethod {
  Download = 'DOWNLOAD',
  Email = 'EMAIL',
  PrintAtHome = 'PRINT_AT_HOME',
  PrintStore = 'PRINT_STORE'
}

export type FetchIthacaPermissionsResultType = {
  __typename?: 'FetchIthacaPermissionsResultType'
  assetId?: Maybe<Scalars['UUID']>
  entityPermissions?: Maybe<Array<Maybe<EntityPermissionObjectType>>>
  result?: Maybe<IthacaPermissionsResultType>
}

export type HandlingOption = {
  __typename?: 'HandlingOption'
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  pricePercentageIncrease?: Maybe<Scalars['Int']>
  productionDaysRangeMax?: Maybe<Scalars['Int']>
  productionDaysRangeMin?: Maybe<Scalars['Int']>
}

export type ImageDetails = {
  __typename?: 'ImageDetails'
  imageUrl: Scalars['String']
  templateDetails: TemplateDetails
}

export type IthacaPermissionsResultType = {
  __typename?: 'IthacaPermissionsResultType'
  error?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export enum Layout {
  Blank = 'BLANK',
  Predesigned = 'PREDESIGNED'
}

export type LinkDjangoType = {
  __typename?: 'LinkDjangoType'
  active: Scalars['Boolean']
  flowcodeId?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  redirectType: LinkRedirectType
  redirectValue: Scalars['String']
  shorturl: Scalars['String']
}

export type LinkInputType = {
  active?: InputMaybe<Scalars['Boolean']>
  redirectType?: InputMaybe<RedirectType>
  redirectValue?: InputMaybe<Scalars['String']>
}

/** An enumeration. */
export enum LinkRedirectType {
  /** COLLECTIBLE_FP */
  CollectibleFp = 'COLLECTIBLE_FP',
  /** EMAIL */
  Email = 'EMAIL',
  /** EVENT */
  Event = 'EVENT',
  /** FACEBOOK */
  Facebook = 'FACEBOOK',
  /** FILE */
  File = 'FILE',
  /** FLOWPAGE */
  Flowpage = 'FLOWPAGE',
  /** GOOGLE_DOC */
  GoogleDoc = 'GOOGLE_DOC',
  /** INSTAGRAM */
  Instagram = 'INSTAGRAM',
  /** LINKEDIN */
  Linkedin = 'LINKEDIN',
  /** ONE_TIME_URL */
  OneTimeUrl = 'ONE_TIME_URL',
  /** PHONE */
  Phone = 'PHONE',
  /** PRESET_FP */
  PresetFp = 'PRESET_FP',
  /** SCAN_TO_ACTIVATE */
  ScanToActivate = 'SCAN_TO_ACTIVATE',
  /** SCAN_TO_OWN */
  ScanToOwn = 'SCAN_TO_OWN',
  /** SMS */
  Sms = 'SMS',
  /** SNAPCHAT */
  Snapchat = 'SNAPCHAT',
  /** SOCIAL */
  Social = 'SOCIAL',
  /** SOCIAL_MEDIA */
  SocialMedia = 'SOCIAL_MEDIA',
  /** SPOTIFY */
  Spotify = 'SPOTIFY',
  /** TIKTOK */
  Tiktok = 'TIKTOK',
  /** TWITTER */
  Twitter = 'TWITTER',
  /** URL */
  Url = 'URL',
  /** VCARD */
  Vcard = 'VCARD',
  /** VENMO */
  Venmo = 'VENMO',
  /** WIFI */
  Wifi = 'WIFI',
  /** YOUTUBE */
  Youtube = 'YOUTUBE'
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * ArchiveBatch is called when the user chooses to archive a code.
   * The archive_reason is set to USER_ARCHIVAL accordingly.
   */
  archiveBatch?: Maybe<ArchiveBatch>
  /**
   * AssignStudioConfig gives a user the ability to use the StudioConfig
   * i.e. used by the FE to give user access to a code design after signing up
   */
  assignStudioConfiguration?: Maybe<AssignStudioConfig>
  /** Used to insert a project in the DB to map the design huddle project to the authenticated user parsed from the JWT. */
  associateProject?: Maybe<AssociateProject>
  /**
   * BulkArchiveBatches is called when the user chooses to archive a list of codes.
   * The archive_reason is set to USER_ARCHIVAL accordingly.
   */
  bulkArchiveBatches?: Maybe<BulkArchiveBatches>
  /** BulkDeleteDirectories deletes a list of directories and all the batches within it */
  bulkDeleteDirectories?: Maybe<BulkDeleteDirectories>
  /** BulkRestoreBatches is the reverse of BulkArchiveBatches and restores a list of codes to be active again. */
  bulkRestoreBatches?: Maybe<BulkRestoreBatches>
  /** BulkUpdateBatchDirectory changes or removes the directory a list of batches are in */
  bulkUpdateBatchDirectory?: Maybe<BulkUpdateBatchDirectory>
  /** BulkUpdateDirectories moves a list of directories in and out of other directories */
  bulkUpdateDirectories?: Maybe<BulkUpdateDirectories>
  /**
   * CollectBatch is used with scan to own codes and creates a BatchOwner for a Batch
   * Switches `is_collectible` to false as scan to own codes are one-time use only.
   *
   * -   CollectBatch will grant the user access to the studio_config design if they do not have it already
   * -   Note: there is no check against ithaca, however that is not a concern since users are net-new.
   *     Worth keeping a pulse on whether or not users of STO have accounts in which the same studio_config could be
   *     assigned to a user
   * -   claimed_page_id is the page_id of the slugName that is getting claimed in the preset onboarding flow. If
   *     we aren't in the preset onboarding flow then claimed_page_id should be None
   */
  collectBatch?: Maybe<CollectBatch>
  /** CreateAssetLabels creates asset labels for the specified batch id. */
  createAssetLabels?: Maybe<CreateAssetLabels>
  /**
   * CreateBatch creates a batch, link, batch_owner, and redirect object
   * It also creates entries in the batch_partial table if partial ids are supplied
   */
  createBatch?: Maybe<CreateBatch>
  /**
   * CreateBatchDesignTemplate creates a BatchDesignTemplate object for a user and configuration
   * BatchDesignTemplate's are saved code configurations that allow users to save custom designs they have made for reuse
   */
  createBatchDesignTemplate?: Maybe<CreateBatchDesignTemplate>
  /**
   * CreateBatchRules takes in a rule and/or an interstitial and merges them into a single config that determines how the batch is handled during a redirect.
   * It updates the CoreBatchRulesHistory table with an entry for each created object.
   */
  createBatchRules?: Maybe<CreateBatchRules>
  /**
   * CreateCollectibleBatch handles creating collectible flowcodes
   * All flowcodes created through this mutation are attached to our internal api_client_id and campaign
   *
   * - In the case that a batchId is passing in, we want to use that code to build a new code for the user.
   * - This code is not a collectible code but a copy of the one that was passed in
   *
   * - In the case that sendEmail is passed in, we send a notification with the new code for the user.
   */
  createCollectibleBatch?: Maybe<CreateCollectibleBatch>
  /**
   * CreateDirectory creates a directory
   * Directories can be nested
   * if batch_ids are supplied the batches are updated to be in the directory
   */
  createDirectory?: Maybe<CreateDirectory>
  /**
   * CreateExternalDomain creates an external domain for a user/org who owns their domain and
   * brings it into the Flowcode ecosystem, and assigns it to that user/org. For example,
   * if BMW owns the domain https://bmw.com/ and wants to create redirect and/or page urls
   * using this domain, this mutation creates the domain in the Domains Service and assigns
   * the BMW org as the owner of the domain.
   */
  createExternalDomain?: Maybe<CreateExternalDomain>
  /** CreatePlacementTemplateCategory creates either a child or root (parentless) category */
  createPlacementTemplateCategory?: Maybe<CreatePlacementTemplateCategory>
  /** CreatePlacementTemplateConfig creates a placement template config with a placeholder asset file */
  createPlacementTemplateConfig?: Maybe<CreatePlacementTemplateConfig>
  /** CreatePlacementTemplateDownloadData records data for a placement template download/print event */
  createPlacementTemplateDownloadData?: Maybe<CreatePlacementTemplateDownloadData>
  /** Used to create a new project in Design Huddle */
  createProject?: Maybe<Project>
  /**
   * CreateRedirectSubdomain creates a subdomain and assigns it to the requesting user,
   * creates entries in the WhiteLabelSubdomain and WhiteLabelSubdomainOwner tables in the domains service,
   * and creates an asset permission entry in ithaca for the user and subdomain.
   */
  createRedirectSubdomain?: Maybe<CreateRedirectSubdomain>
  /**
   * CreateStudioConfig creates a StudioConfig and assigns source
   * Used for code creation and duplication
   * During custom code creation the FE will call this mutation then CreateBatch
   */
  createStudioConfig?: Maybe<CreateStudioConfig>
  /** DeleteAssetLabels deletes asset labels for the specified asset label ids. */
  deleteAssetLabels?: Maybe<DeleteAssetLabels>
  /**
   * DeleteBatchDesignTemplate deletes a BatchDesignTemplate object.
   * To be used from internal admin, and requires super admin access.
   */
  deleteBatchDesignTemplate?: Maybe<DeleteBatchDesignTemplate>
  /**
   * DeleteBatchRules takes in should_delete_rule and should_delete_interstitial and removes the corresponding objects from the config.
   * If there is no remaining config after deletion, the entry will be deleted from the db.
   * Updates the CoreBatchRulesHistory table with an entry for each deleted object.
   */
  deleteBatchRules?: Maybe<DeleteBatchRules>
  /** DeleteDirectory deletes a directory and all the batches within it */
  deleteDirectory?: Maybe<DeleteDirectory>
  /**
   * DeleteExternalDomain soft-deletes an external domain. The operation will fail if the domain
   * has active/verified subdomains associated with it, or if the domain does not exist.
   */
  deleteExternalDomain?: Maybe<DeleteExternalDomain>
  /** DeletePlacementTemplateCategory deletes a placement template */
  deletePlacementTemplateCategory?: Maybe<DeletePlacementTemplateCategory>
  /** DeletePlacementTemplateConfig deletes a PlacementTemplateConfig */
  deletePlacementTemplateConfig?: Maybe<DeletePlacementTemplateConfig>
  /** Used to delete an existing project */
  deleteProject?: Maybe<DeleteProject>
  /**
   * Given subdomainId, soft deletes the associated subdomain from the domains service db and marks
   * the WhiteLabelRedirectDomain record as inactive, and deletes any shared permissions for the subdomain.
   */
  deleteRedirectSubdomain?: Maybe<DeleteRedirectSubdomain>
  /**
   * EditBatchDesign creates a new StudioConfig and points the batch to it
   * This is used when switching to a non-premade design
   */
  editBatchDesign?: Maybe<EditBatchDesign>
  /** EditConfiguration alters the configuration column of a studio config */
  editConfiguration?: Maybe<EditConfiguration>
  /**
   * EditStudioConfigRasterization sets the noRasterize option for a StudioConfig.
   * Any config, regardless of pubished status, can be updated.
   */
  editStudioConfigRasterization?: Maybe<EditStudioConfigRasterization>
  /**
   * EmailBatch takes in the email_type parameter to determine which email to send out to the user.
   * - code_sent / code_sent_self: sends the flowcode to the user
   * - tag_referred: sends an email with a link to get a free flowtag
   *
   * :param: data - cannot contain keys: 'event_timestamp', 'batch_id' or 'to_email'
   */
  emailBatch?: Maybe<EmailBatch>
  /**
   * PrintStoreCheckout creates a AssetFile and CartItem for each item in a Cart
   * Send result to payments client for processing
   *
   * Flowcode assets (as well as internal templates) are generated using flowcode's generator
   *
   * Design huddle template assets are generated using design huddle's api. The following fields
   * must be provided for an item with a design huddle template:
   * dh_template_id, dh_project_id, dh_project_export_id
   */
  printStoreCheckout?: Maybe<PrintStoreCheckout>
  /** When a non print store export of the project happens (i.e. download, print at home, email to self), the caller calls this endpoint to save a record of that export event. Parses user from JWT. */
  projectExport?: Maybe<CreateProjectExport>
  /**
   * PublishStudioConfig sets the published on date for a StudioConfig
   * PublishedConfigs cannot be edited
   */
  publishStudioConfiguration?: Maybe<PublishStudioConfig>
  /**
   * Given subdomainId, attempts to check if the associated subdomain has been verified in cloudflare;
   * reflects any changes in verification status to the is_active field in WhiteLabelRedirectDomain.
   */
  refreshRedirectSubdomainStatus?: Maybe<RefreshRedirectSubdomainStatus>
  /** RenameAssetFile updates the name of any asset */
  renameAssetFile?: Maybe<RenameAssetFile>
  /** RestoreBatch is the reverse of archive and makes a code active again */
  restoreBatch?: Maybe<RestoreBatch>
  /**
   * RestoreExternalDomain attempts to restore a soft-deleted external domain. The operation will
   * fail if restoring the domain would result in exceeding the user/org's external domain limit,
   * or if the domain does not exist.
   */
  restoreExternalDomain?: Maybe<RestoreExternalDomain>
  /**
   * Given subdomainId, restores a soft deleted subdomain in the domains service db. Sets is_active to True
   * on the WhiteLabelRedirectDomain object if the subdomain is cobranded.
   */
  restoreRedirectSubdomain?: Maybe<RestoreRedirectSubdomain>
  /** Used to save and persist a brand kit for the authenticated user. Parses user from JWT. */
  saveBrandKit?: Maybe<SaveBrandKit>
  /** Mutation that handles the sharing of various assets. */
  shareIthacaAssets?: Maybe<ShareIthacaAssets>
  /**
   * ShoppingCartAddItem adds an item to a shopping cart. If no cart exists it creates one.
   * Adds an entry into shopping cart history
   *
   * template_id represents the id of an internally created template.
   * dh_template_id is the id of a template in design huddles system.
   * Only one of these fields (or neither) should be provided
   *
   * If dh_template_id is provided, dh_project_id must be provided as well
   */
  shoppingCartAddItem?: Maybe<ShoppingCartAddItem>
  /**
   * ShoppingCartDeleteItem removes an item from the shopping cart
   * Adds an entry into ShoppingCartHistory table
   */
  shoppingCartDeleteItem?: Maybe<ShoppingCartDeleteItem>
  /**
   * ShoppingCartEditItemQuantity adjusts the quanity for a given item within a shopping cart
   * Adds an entry into ShoppingCartHistory table
   */
  shoppingCartEditItemQuantity?: Maybe<ShoppingCartEditItemQuantity>
  /**
   * ShoppingCartOrderPlaced deletes the ShoppingCart object
   * Adds an entry into ShoppingCartHistory table
   * Does not send a request to payments client
   */
  shoppingCartOrderPlaced?: Maybe<ShoppingCartOrderPlaced>
  /**
   * SwitchBatchDesign changes which StudioConfig a batch points to
   * this is used when switching to a premade design i.e. BLM or Earth Day
   */
  switchBatchDesign?: Maybe<SwitchBatchDesign>
  /**
   * This mutation is a custom endpoint to service the centre_bell stadium for one of our
   * enterprise clients. This endpoint is restricted for use by our CS team and will service
   * the 2023/2024 season
   */
  toggleCanadienCodes?: Maybe<ToggleCanadiensCodes>
  /**
   * TransferBatch updates the owner of the batch to be a different user
   * Creates an entry in BatchHistory to keep a log of owners
   */
  transferBatch?: Maybe<TransferBatch>
  /** TransferDirectory updates the owner of a directory. */
  transferDirectory?: Maybe<TransferDirectory>
  /** Mutation that handles the unsharing of various assets. */
  unshareIthacaAssets?: Maybe<UnshareIthacaAssets>
  /** UnsubscribeHubspot removes a user from our hubspot mailing list by sending a request to notifications */
  unsubscribeHubspot?: Maybe<UnsubscribeHubspot>
  /** UpdateAssetTags creates an entry in the AssetFile_AssetTag for each tag supplied and the asset file */
  updateAssetTags?: Maybe<UpdateAssetTags>
  /** UpdateBatchDirectory changes or removes the directory a batch is in */
  updateBatchDirectory?: Maybe<UpdateBatchDirectory>
  /** UpdateBatchLink updates the batch and/or the batch link. */
  updateBatchLink?: Maybe<UpdateBatchLink>
  /** UpdateBatchRules takes a rule and/or an interstitial config and updates them. It updates the CoreBatchRulesHistory table with an entry for each updated object. */
  updateBatchRules?: Maybe<UpdateBatchRules>
  updateCampaignName?: Maybe<UpdateCampaignName>
  /**
   * UpdateStudioConfig updates the ithaca subscription plans, display_to_all value, users, or
   * asset_file column of an existing StudioConfig
   * Does not update the config column - the column that controls code design (see EditConfiguration)
   */
  updateConfiguration?: Maybe<UpdateStudioConfig>
  /** UpdateDirectory updates name and or parent id of a directory */
  updateDirectory?: Maybe<UpdateDirectory>
  /** UpdatePlcaementTemplateCategory updates a PlacementTemplateCategory */
  updatePlacementTemplateCategory?: Maybe<UpdatePlacementTemplateCategory>
  /**
   * UpdatePlacementTemplateConfig updates a placement template config
   * setting toggle publish to true will remove the published date if it exists or sets it to now if it does not
   */
  updatePlacementTemplateConfig?: Maybe<UpdatePlacementTemplateConfig>
  /** UpdateSendgridSubscription modifies the subscription status of the user in Sendgrid */
  updateSendgridSubscription?: Maybe<UpdateSendgridSubscription>
}

export type MutationArchiveBatchArgs = {
  archiveReason?: InputMaybe<Scalars['String']>
  batchId: Scalars['ID']
  deactivateRedirectService?: InputMaybe<Scalars['Boolean']>
}

export type MutationAssignStudioConfigurationArgs = {
  configId: Scalars['UUID']
}

export type MutationAssociateProjectArgs = {
  dhProjectId: Scalars['String']
  dhUserId: Scalars['String']
  projectTitle: Scalars['String']
  sourceTemplateId: Scalars['UUID']
}

export type MutationBulkArchiveBatchesArgs = {
  archiveReason?: InputMaybe<Scalars['String']>
  batchIds: Array<InputMaybe<Scalars['ID']>>
  deactivateRedirectService?: InputMaybe<Scalars['Boolean']>
}

export type MutationBulkDeleteDirectoriesArgs = {
  directoryIds: Array<InputMaybe<Scalars['ID']>>
}

export type MutationBulkRestoreBatchesArgs = {
  batchIds: Array<InputMaybe<Scalars['ID']>>
}

export type MutationBulkUpdateBatchDirectoryArgs = {
  batchIds: Array<InputMaybe<Scalars['ID']>>
  moveToDirectory?: InputMaybe<Scalars['ID']>
  removeDirectory?: InputMaybe<Scalars['Boolean']>
}

export type MutationBulkUpdateDirectoriesArgs = {
  directoryIds: Array<InputMaybe<Scalars['ID']>>
  newParentId?: InputMaybe<Scalars['ID']>
  shouldMoveToRoot?: InputMaybe<Scalars['Boolean']>
}

export type MutationCollectBatchArgs = {
  batchId?: InputMaybe<Scalars['ID']>
  codeInput?: InputMaybe<CollectBatchCodeInputType>
  flowcodeId?: InputMaybe<Scalars['ID']>
}

export type MutationCreateAssetLabelsArgs = {
  batchId: Scalars['ID']
  names: Array<InputMaybe<Scalars['String']>>
}

export type MutationCreateBatchArgs = {
  active?: InputMaybe<Scalars['Boolean']>
  anonymousSessionId?: InputMaybe<Scalars['UUID']>
  assetLabels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  campaignName?: InputMaybe<CombinedGeneratorCampaigns>
  creationSource?: InputMaybe<CreationSource>
  isCollectible?: InputMaybe<Scalars['Boolean']>
  isStaticCode?: InputMaybe<Scalars['Boolean']>
  nickname?: InputMaybe<Scalars['String']>
  partialIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
  redirectDomain?: InputMaybe<Scalars['String']>
  redirectType: RedirectType
  studioConfigId?: InputMaybe<Scalars['UUID']>
  url: Scalars['String']
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationCreateBatchDesignTemplateArgs = {
  input?: InputMaybe<BatchDesignTemplateInputType>
}

export type MutationCreateBatchRulesArgs = {
  batchId: Scalars['ID']
  interstitial?: InputMaybe<Scalars['JSONString']>
  rule?: InputMaybe<Scalars['JSONString']>
}

export type MutationCreateCollectibleBatchArgs = {
  batchId?: InputMaybe<Scalars['ID']>
  campaignName?: InputMaybe<PrebuildCollectibleCampaigns>
  nickname?: InputMaybe<Scalars['String']>
  partialIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
  sendEmail?: InputMaybe<SendCollectBatchEmailInputType>
  studioConfigId?: InputMaybe<Scalars['UUID']>
}

export type MutationCreateDirectoryArgs = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['ID']>
}

export type MutationCreateExternalDomainArgs = {
  domain: Scalars['String']
}

export type MutationCreatePlacementTemplateCategoryArgs = {
  name: Scalars['String']
  parentCategoryId?: InputMaybe<Scalars['UUID']>
  tileDisplay?: InputMaybe<TileDisplayFormat>
}

export type MutationCreatePlacementTemplateConfigArgs = {
  categoryId: Scalars['UUID']
  configuration: Scalars['JSONString']
  name: Scalars['String']
  placeholderAssetFileId: Scalars['UUID']
}

export type MutationCreatePlacementTemplateDownloadDataArgs = {
  actionType: Scalars['String']
  flowcodeId: Scalars['String']
  templateGenerationDetails: Scalars['String']
  templateId: Scalars['ID']
}

export type MutationCreateProjectArgs = {
  customizations?: InputMaybe<Scalars['JSONString']>
  dhUserId: Scalars['String']
  projectTitle: Scalars['String']
  sourceTemplateId: Scalars['UUID']
}

export type MutationCreateRedirectSubdomainArgs = {
  rootDomainId: Scalars['String']
  subdomain: Scalars['String']
}

export type MutationCreateStudioConfigArgs = {
  configuration?: InputMaybe<Scalars['JSONString']>
  name?: InputMaybe<Scalars['String']>
  source?: InputMaybe<SourceType>
}

export type MutationDeleteAssetLabelsArgs = {
  assetLabelIds: Array<InputMaybe<Scalars['UUID']>>
}

export type MutationDeleteBatchDesignTemplateArgs = {
  templateId: Scalars['ID']
}

export type MutationDeleteBatchRulesArgs = {
  ruleId: Scalars['ID']
  shouldDeleteInterstitial?: InputMaybe<Scalars['Boolean']>
  shouldDeleteRule?: InputMaybe<Scalars['Boolean']>
}

export type MutationDeleteDirectoryArgs = {
  directoryId: Scalars['ID']
}

export type MutationDeleteExternalDomainArgs = {
  domainId: Scalars['String']
}

export type MutationDeletePlacementTemplateCategoryArgs = {
  placementTemplateCategoryId: Scalars['UUID']
}

export type MutationDeletePlacementTemplateConfigArgs = {
  placementTemplateConfigId: Scalars['UUID']
}

export type MutationDeleteProjectArgs = {
  dhProjectId: Scalars['String']
}

export type MutationDeleteRedirectSubdomainArgs = {
  subdomainId: Scalars['String']
}

export type MutationEditBatchDesignArgs = {
  id: Scalars['String']
  isAutoDesign?: InputMaybe<Scalars['Boolean']>
  newConfig: Scalars['JSONString']
  partialIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
}

export type MutationEditConfigurationArgs = {
  configId: Scalars['UUID']
  configuration: Scalars['JSONString']
}

export type MutationEditStudioConfigRasterizationArgs = {
  configId?: InputMaybe<Scalars['UUID']>
  rasterize?: InputMaybe<Scalars['Boolean']>
}

export type MutationEmailBatchArgs = {
  batchId?: InputMaybe<Scalars['ID']>
  data?: InputMaybe<Scalars['JSONString']>
  emailType?: InputMaybe<EmailType>
  toEmail: Scalars['String']
}

export type MutationPrintStoreCheckoutArgs = {
  checkoutData: CheckoutInput
}

export type MutationProjectExportArgs = {
  dhProjectData?: InputMaybe<Scalars['JSONString']>
  dhProjectId: Scalars['String']
  exportMethod: ExportMethod
  flowcodeId: Scalars['String']
}

export type MutationPublishStudioConfigurationArgs = {
  configId?: InputMaybe<Scalars['UUID']>
}

export type MutationRefreshRedirectSubdomainStatusArgs = {
  subdomainId: Scalars['String']
}

export type MutationRenameAssetFileArgs = {
  assetFileId: Scalars['UUID']
  name: Scalars['String']
}

export type MutationRestoreBatchArgs = {
  batchId: Scalars['ID']
}

export type MutationRestoreExternalDomainArgs = {
  domainId: Scalars['String']
}

export type MutationRestoreRedirectSubdomainArgs = {
  subdomainId: Scalars['String']
}

export type MutationSaveBrandKitArgs = {
  brandKitInput: BrandKitInput
}

export type MutationShareIthacaAssetsArgs = {
  assetIds: Array<InputMaybe<Scalars['UUID']>>
  assetType: Scalars['String']
  entityPermissions: Array<InputMaybe<EntityPermissionInputType>>
}

export type MutationShoppingCartAddItemArgs = {
  batchId?: InputMaybe<Scalars['String']>
  dhProjectCustomization?: InputMaybe<Scalars['JSONString']>
  dhProjectId?: InputMaybe<Scalars['String']>
  dhTemplateId?: InputMaybe<Scalars['String']>
  priceId: Scalars['String']
  productId: Scalars['String']
  quantity: Scalars['Int']
  templateDetails?: InputMaybe<Scalars['JSONString']>
  templateId?: InputMaybe<Scalars['String']>
}

export type MutationShoppingCartDeleteItemArgs = {
  itemId: Scalars['String']
}

export type MutationShoppingCartEditItemQuantityArgs = {
  itemId: Scalars['String']
  newQuantity: Scalars['Int']
}

export type MutationShoppingCartOrderPlacedArgs = {
  cartId: Scalars['String']
}

export type MutationSwitchBatchDesignArgs = {
  id: Scalars['String']
  newConfigId: Scalars['String']
  partialIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
}

export type MutationToggleCanadienCodesArgs = {
  mode: CanadiensMode
}

export type MutationTransferBatchArgs = {
  batchId: Scalars['ID']
  newOwnerEmail: Scalars['String']
}

export type MutationTransferDirectoryArgs = {
  directoryId: Scalars['ID']
  newOwnerEmail: Scalars['String']
}

export type MutationUnshareIthacaAssetsArgs = {
  assetIds: Array<InputMaybe<Scalars['UUID']>>
  assetType: Scalars['String']
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
}

export type MutationUnsubscribeHubspotArgs = {
  email: Scalars['String']
}

export type MutationUpdateAssetTagsArgs = {
  assetFileId?: InputMaybe<Scalars['UUID']>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationUpdateBatchDirectoryArgs = {
  batchId: Scalars['ID']
  moveToDirectory?: InputMaybe<Scalars['ID']>
  removeDirectory?: InputMaybe<Scalars['Boolean']>
}

export type MutationUpdateBatchLinkArgs = {
  batchId: Scalars['ID']
  batchInput?: InputMaybe<BatchInputType>
  linkInput?: InputMaybe<LinkInputType>
}

export type MutationUpdateBatchRulesArgs = {
  batchId: Scalars['ID']
  interstitial?: InputMaybe<Scalars['JSONString']>
  rule?: InputMaybe<Scalars['JSONString']>
}

export type MutationUpdateCampaignNameArgs = {
  campaignId: Scalars['ID']
  newName: Scalars['String']
}

export type MutationUpdateConfigurationArgs = {
  args?: InputMaybe<StudioConfigInputType>
}

export type MutationUpdateDirectoryArgs = {
  description?: InputMaybe<Scalars['String']>
  directoryId: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  parentId?: InputMaybe<Scalars['ID']>
  shouldMoveToRoot?: InputMaybe<Scalars['Boolean']>
}

export type MutationUpdatePlacementTemplateCategoryArgs = {
  name?: InputMaybe<Scalars['String']>
  parentCategoryId?: InputMaybe<Scalars['UUID']>
  placementTemplateCategoryId: Scalars['UUID']
  setParentNull?: InputMaybe<Scalars['Boolean']>
  tileDisplay?: InputMaybe<TileDisplayFormat>
}

export type MutationUpdatePlacementTemplateConfigArgs = {
  categoryId?: InputMaybe<Scalars['UUID']>
  configuration?: InputMaybe<Scalars['JSONString']>
  name?: InputMaybe<Scalars['String']>
  placeholderAssetFileId?: InputMaybe<Scalars['UUID']>
  placementTemplateConfigId: Scalars['UUID']
  togglePublish?: InputMaybe<Scalars['Boolean']>
}

export type MutationUpdateSendgridSubscriptionArgs = {
  email: Scalars['String']
  shouldUnsubscribe?: InputMaybe<Scalars['Boolean']>
}

export type NestedDirectoriesDetailType = {
  __typename?: 'NestedDirectoriesDetailType'
  nestedDirs?: Maybe<Array<Maybe<NestedDirectoryDetailType>>>
}

export type NestedDirectoryDetailType = {
  __typename?: 'NestedDirectoryDetailType'
  children?: Maybe<Array<Maybe<DirectoryDetailType>>>
  id?: Maybe<Scalars['Int']>
  isRoot?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
}

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']
}

export type NotificationSubscriptionStatuses = {
  __typename?: 'NotificationSubscriptionStatuses'
  hubspot?: Maybe<Scalars['Boolean']>
  sendgrid?: Maybe<Scalars['Boolean']>
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Scalars['String']
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Scalars['String']
}

export enum PageType {
  TrashPage = 'TRASH_PAGE'
}

/** This is what will be returned to the frontend when fetching paginated products. */
export type PaginatedProductsResponse = {
  __typename?: 'PaginatedProductsResponse'
  paginationInfo?: Maybe<PaginationInfo>
  products?: Maybe<Array<Maybe<Product>>>
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage?: Maybe<Scalars['Boolean']>
  hasPreviousPage?: Maybe<Scalars['Boolean']>
  startCursor?: Maybe<Scalars['String']>
}

export type PlacementTemplateCategoryObjectType = Node & {
  __typename?: 'PlacementTemplateCategoryObjectType'
  /** The ID of the object. */
  id: Scalars['ID']
  isDigital: Scalars['Boolean']
  name: Scalars['String']
  templates?: Maybe<Array<Maybe<PlacementTemplateConfigObjectType>>>
  tileDisplay: PlacementTemplateCategoryTileDisplay
  type: PlacementTemplateCategoryType
}

export type PlacementTemplateCategoryObjectTypeTemplatesArgs = {
  customizations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isPrintable?: InputMaybe<Scalars['Boolean']>
  isPurchasable?: InputMaybe<Scalars['Boolean']>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PlacementTemplateCategoryObjectTypeConnection = {
  __typename?: 'PlacementTemplateCategoryObjectTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlacementTemplateCategoryObjectTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `PlacementTemplateCategoryObjectType` and its cursor. */
export type PlacementTemplateCategoryObjectTypeEdge = {
  __typename?: 'PlacementTemplateCategoryObjectTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PlacementTemplateCategoryObjectType>
}

/** An enumeration. */
export enum PlacementTemplateCategoryTileDisplay {
  /** normal */
  Normal = 'NORMAL',
  /** wide */
  Wide = 'WIDE'
}

/** An enumeration. */
export enum PlacementTemplateCategoryType {
  /** curated */
  Curated = 'CURATED',
  /** format */
  Format = 'FORMAT'
}

export type PlacementTemplateConfigObjectType = Node & {
  __typename?: 'PlacementTemplateConfigObjectType'
  category?: Maybe<PlacementTemplateCategoryObjectType>
  configuration: Scalars['JSONString']
  /** The ID of the object. */
  id: Scalars['ID']
  isCustomizable?: Maybe<Scalars['Boolean']>
  isPrintable: Scalars['Boolean']
  isPurchasable: Scalars['Boolean']
  isSingleFlowcode: Scalars['Boolean']
  name: Scalars['String']
  placeholderAssetFile?: Maybe<AssetFileObjectType>
  size: Scalars['String']
  sizeCount?: Maybe<Scalars['Int']>
}

export type PlacementTemplateConfigObjectTypeConnection = {
  __typename?: 'PlacementTemplateConfigObjectTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlacementTemplateConfigObjectTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `PlacementTemplateConfigObjectType` and its cursor. */
export type PlacementTemplateConfigObjectTypeEdge = {
  __typename?: 'PlacementTemplateConfigObjectTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PlacementTemplateConfigObjectType>
}

export enum PrebuildCollectibleCampaigns {
  OptiCombinedGenerator_1 = 'OPTI_COMBINED_GENERATOR_1',
  OptiCombinedGenerator_2 = 'OPTI_COMBINED_GENERATOR_2',
  OptiCombinedGenerator_3 = 'OPTI_COMBINED_GENERATOR_3',
  OptiCombinedGenerator_4 = 'OPTI_COMBINED_GENERATOR_4',
  StarterKit = 'STARTER_KIT'
}

export type PresetLinkType = {
  __typename?: 'PresetLinkType'
  redirectType?: Maybe<RedirectType>
  redirectValue?: Maybe<Scalars['String']>
}

export type PriceModel = {
  __typename?: 'PriceModel'
  priceType?: Maybe<PriceType>
  sortedTiers?: Maybe<Array<Maybe<PriceTier>>>
}

export type PriceTier = {
  __typename?: 'PriceTier'
  quantity?: Maybe<Scalars['Int']>
  unitAmount?: Maybe<Scalars['Int']>
}

export enum PriceType {
  Fixed = 'FIXED'
}

/**
 * PrintStoreCheckout creates a AssetFile and CartItem for each item in a Cart
 * Send result to payments client for processing
 *
 * Flowcode assets (as well as internal templates) are generated using flowcode's generator
 *
 * Design huddle template assets are generated using design huddle's api. The following fields
 * must be provided for an item with a design huddle template:
 * dh_template_id, dh_project_id, dh_project_export_id
 */
export type PrintStoreCheckout = {
  __typename?: 'PrintStoreCheckout'
  ok?: Maybe<Scalars['Boolean']>
  stripeCheckoutSessionId?: Maybe<Scalars['String']>
}

export type Product = {
  __typename?: 'Product'
  availableQuantity?: Maybe<Scalars['Int']>
  categoryIds?: Maybe<Array<Maybe<Scalars['String']>>>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  handlingTimeBusinessDays?: Maybe<Scalars['Int']>
  headerText?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  isCustomizable?: Maybe<Scalars['Boolean']>
  maxOrderQuantity?: Maybe<Scalars['Int']>
  minOrderQuantity?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  orderQuantityIncrement?: Maybe<Scalars['Int']>
  priceId?: Maybe<Scalars['String']>
  priceModel?: Maybe<PriceModel>
  shippingOptionIds?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** This is what will be returned to the frontend when requesting product categories. */
export type ProductCategoriesResponse = {
  __typename?: 'ProductCategoriesResponse'
  paginationInfo?: Maybe<PaginationInfo>
  productCategories?: Maybe<Array<Maybe<ProductCategory>>>
}

export type ProductCategory = {
  __typename?: 'ProductCategory'
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isTopLevelCategory?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  productBannerImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  productBannerText?: Maybe<Scalars['String']>
  supportsCustomization?: Maybe<Scalars['Boolean']>
  templateBannerImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  templateBannerText?: Maybe<Scalars['String']>
  tileImageUrl?: Maybe<Scalars['String']>
}

export type ProductCategoryWithProducts = {
  __typename?: 'ProductCategoryWithProducts'
  description?: Maybe<Scalars['String']>
  hasMoreProducts?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  isTopLevelCategory?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  productBannerImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  productBannerText?: Maybe<Scalars['String']>
  products?: Maybe<Array<Maybe<Product>>>
  supportsCustomization?: Maybe<Scalars['Boolean']>
  templateBannerImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>
  templateBannerText?: Maybe<Scalars['String']>
  tileImageUrl?: Maybe<Scalars['String']>
}

/** This is what will be returned to the frontend for a single product details page. */
export type ProductResponse = {
  __typename?: 'ProductResponse'
  product?: Maybe<Product>
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>
}

/** This is what will be returned to the frontend to display in the print store. */
export type ProductsResponse = {
  __typename?: 'ProductsResponse'
  products?: Maybe<Array<Maybe<Product>>>
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>
}

export type Project = {
  __typename?: 'Project'
  dhProjectId: Scalars['String']
  dhTemplateId: Scalars['String']
  projectId: Scalars['UUID']
  templateId: Scalars['UUID']
  title: Scalars['String']
}

/**
 * PublishStudioConfig sets the published on date for a StudioConfig
 * PublishedConfigs cannot be edited
 */
export type PublishStudioConfig = {
  __typename?: 'PublishStudioConfig'
  ok?: Maybe<Scalars['Boolean']>
  publishedOn?: Maybe<Scalars['DateTime']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

export type Query = {
  __typename?: 'Query'
  _service?: Maybe<_Service>
  allBorderDesignConfigs?: Maybe<Array<Maybe<BorderDesignObjectType>>>
  allNestedDirectories?: Maybe<NestedDirectoriesDetailType>
  assetLabels?: Maybe<AssetLabelTypeConnection>
  assignedFlowcodeTemplates?: Maybe<StudioConfigObjectTypeConnection>
  autoDesignConfigs?: Maybe<Array<Maybe<AutoDesignObjectType>>>
  autoDesignsFromBrandData?: Maybe<Array<Maybe<AutoDesignObjectType>>>
  autoDesignsFromLogo?: Maybe<Array<Maybe<AutoDesignObjectType>>>
  autoDesignsFromUrl?: Maybe<Array<Maybe<AutoDesignObjectType>>>
  batch?: Maybe<BatchDjangoType>
  batchesForSubdomains?: Maybe<Array<Maybe<BatchDjangoType>>>
  batchRules?: Maybe<RuleBasedObjectType>
  batchTemplates?: Maybe<Array<Maybe<BatchDesignTemplateObjectType>>>
  borderDesignConfigs?: Maybe<Array<Maybe<BorderDesignObjectType>>>
  campaigns?: Maybe<ApiClientCampaignTypeConnection>
  collectible?: Maybe<CollectibleResponseType>
  customAssignedCodes?: Maybe<Array<Maybe<StudioConfigObjectType>>>
  defaultFlowcodeTemplates?: Maybe<StudioConfigObjectTypeConnection>
  directories?: Maybe<DirectoryManagementType>
  directory?: Maybe<DirectoryType>
  domainManagementDetails?: Maybe<Array<Maybe<WhiteLabelDomainManagementType>>>
  estimatedDeliveryDate?: Maybe<EstimatedDeliveryDateResponse>
  flowcodeTemplates?: Maybe<Array<Maybe<StudioConfigObjectType>>>
  /** Query to fetch flowcodes that are tied to a flowpage */
  flowpageCodes?: Maybe<Array<Maybe<BatchDjangoType>>>
  /** Get brand kit for the authenticated user. Parses user from JWT. Can return empty fields if no brand kit exists. */
  getBrandKit?: Maybe<BrandKit>
  /** Get template categories. Authentication not needed. The categories are not sorted in any particular way. Use `after` and `limit` for pagination. Max limit is 1000. */
  getCategories?: Maybe<Categories>
  /** Retrieve `ImageDetails` given a `dhProjectId`. This will be used when a user wants to download or order a print of a design project. The `imageUrl` will be an S3 url that does not expire. This endpoint is implemented by polling Design Huddle, so it may take a couple seconds to return. Also returns `TemplateDetails` with `height`, `width`, `dimensionUnit`, and `templateId` for display. */
  getImageUrl?: Maybe<ImageDetails>
  /** Get template by `templateId`. Authentication not needed. */
  getTemplate?: Maybe<Template>
  /** Get templates, can optionally filter by `productId` and `categoryIds`. Authentication not needed. All layout types are included by default. Specify which layouts to return by passing `layouts` (example: `[BLANK]`). Sorted to return templates ordered by `dhTemplateId`. This is paginated. */
  getTemplates?: Maybe<Templates>
  /** Retrieve `ImageDetails` given a `dhProjectId`. This will be used when a user wants to download or order a print of a design project. The `imageUrl` will be the url directly from Design Huddle, and it will be active for the duration of the auth token that created it. This endpoint is implemented by polling Design Huddle, so it may take a couple seconds to return. Also returns `TemplateDetails` with `height`, `width`, `dimensionUnit`, and `templateId` for display. */
  getTemporaryImageUrl?: Maybe<ImageDetails>
  me?: Maybe<UserType>
  myFlowcodes?: Maybe<CodeManagementType>
  notificationSubscriptionStatuses?: Maybe<NotificationSubscriptionStatuses>
  placementTemplateCategories?: Maybe<PlacementTemplateCategoryObjectTypeConnection>
  placementTemplateConfig?: Maybe<PlacementTemplateConfigObjectType>
  placementTemplateConfigGroup?: Maybe<Array<Maybe<PlacementTemplateConfigObjectType>>>
  placementTemplateConfigs?: Maybe<PlacementTemplateConfigObjectTypeConnection>
  placementTemplateConfigsByCategoryName?: Maybe<PlacementTemplateConfigObjectTypeConnection>
  presetLink?: Maybe<PresetLinkType>
  printStoreCategoryProductMappings?: Maybe<CategoryProductMappingsResponse>
  printStoreDiscountsByPlan?: Maybe<AccountDiscounts>
  printStorePaginatedProducts?: Maybe<PaginatedProductsResponse>
  printStoreProduct?: Maybe<ProductResponse>
  printStoreProductCategories?: Maybe<ProductCategoriesResponse>
  printStoreProducts?: Maybe<ProductsResponse>
  redirectDomains?: Maybe<Array<Maybe<WhiteLabelRedirectDomainType>>>
  redirectSubdomainPermissions?: Maybe<Array<Maybe<FetchIthacaPermissionsResultType>>>
  rootCategories?: Maybe<PlacementTemplateCategoryObjectTypeConnection>
  shoppingCart?: Maybe<ShoppingCartResponse>
  /** protected route for studio users only */
  studio?: Maybe<StudioObjectType>
  studioConfig?: Maybe<StudioConfigObjectType>
  studioConfigEntityPermissions?: Maybe<Array<Maybe<Scalars['UUID']>>>
  subCategories?: Maybe<PlacementTemplateCategoryObjectTypeConnection>
  subscriptionDetails?: Maybe<SubscriptionDetailType>
  subscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlanType>>>
  templatePrintStoreProducts?: Maybe<ProductsResponse>
  users?: Maybe<Array<Maybe<UserType>>>
  userSavedFlowcodeTemplates?: Maybe<BatchDesignTemplateObjectTypeConnection>
  whiteLabelRedirectDomains?: Maybe<Array<Maybe<WhiteLabelRedirectDomainType>>>
}

export type QueryAssetLabelsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isRecommended?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryAssignedFlowcodeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  entityTypesFilter?: InputMaybe<Array<InputMaybe<EntityTypes>>>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['UUID']>
  includeDefaultTemplates?: InputMaybe<Scalars['Boolean']>
  isCustomizable?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
  name_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  publishedOn_Isnull?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
}

export type QueryAutoDesignConfigsArgs = {
  count?: InputMaybe<Scalars['Int']>
  ignoreColorPriority?: InputMaybe<Scalars['Boolean']>
  logoFillSvgColor?: InputMaybe<Scalars['String']>
  logoHeight?: InputMaybe<Scalars['Int']>
  logoImageUrl?: InputMaybe<Scalars['String']>
  logoWidth?: InputMaybe<Scalars['Int']>
  replaceValues?: InputMaybe<Scalars['JSONString']>
}

export type QueryAutoDesignsFromBrandDataArgs = {
  bottomRimText?: InputMaybe<Scalars['String']>
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  count?: InputMaybe<Scalars['Int']>
  logos?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  topRimText?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type QueryAutoDesignsFromLogoArgs = {
  count?: Scalars['Int']
  logoImageUrl: Scalars['String']
}

export type QueryAutoDesignsFromUrlArgs = {
  count?: Scalars['Int']
  url: Scalars['String']
}

export type QueryBatchArgs = {
  batchId?: InputMaybe<Scalars['ID']>
  scopePermissions?: InputMaybe<Scalars['Boolean']>
}

export type QueryBatchesForSubdomainsArgs = {
  subdomainIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
}

export type QueryBatchRulesArgs = {
  batchId?: InputMaybe<Scalars['ID']>
}

export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
  name_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  prebuildOnly?: InputMaybe<Scalars['Boolean']>
}

export type QueryCollectibleArgs = {
  batchId?: InputMaybe<Scalars['ID']>
  flowcodeId?: InputMaybe<Scalars['ID']>
}

export type QueryDefaultFlowcodeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['UUID']>
  isCustomizable?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
  name_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  publishedOn_Isnull?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
}

export type QueryDirectoriesArgs = {
  dirsNameContains?: InputMaybe<Scalars['String']>
  entityId?: InputMaybe<Scalars['ID']>
  entityType?: InputMaybe<Scalars['Int']>
  orderDirs?: InputMaybe<Scalars['String']>
  parentDirectoryId?: InputMaybe<Scalars['ID']>
}

export type QueryDirectoryArgs = {
  directoryId?: InputMaybe<Scalars['ID']>
  scopePermissions?: InputMaybe<Scalars['Boolean']>
}

export type QueryEstimatedDeliveryDateArgs = {
  handlingOptionId: Scalars['ID']
  shippingOptionId: Scalars['ID']
}

export type QueryFlowpageCodesArgs = {
  url: Scalars['String']
}

export type QueryGetCategoriesArgs = {
  after: Scalars['String']
  limit: Scalars['Int']
}

export type QueryGetImageUrlArgs = {
  dhProjectId: Scalars['String']
  format?: InputMaybe<ExportFormat>
  projectExportId?: InputMaybe<Scalars['UUID']>
}

export type QueryGetTemplateArgs = {
  templateId: Scalars['UUID']
}

export type QueryGetTemplatesArgs = {
  after: Scalars['String']
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
  fetchProducts?: InputMaybe<Scalars['Boolean']>
  layouts?: InputMaybe<Array<InputMaybe<Layout>>>
  limit: Scalars['Int']
  productId?: InputMaybe<Scalars['UUID']>
}

export type QueryGetTemporaryImageUrlArgs = {
  dhProjectId: Scalars['String']
  format?: InputMaybe<ExportFormat>
}

export type QueryMyFlowcodesArgs = {
  allbatchesArchived?: InputMaybe<Scalars['Boolean']>
  allbatchesExcludeCreationSource?: InputMaybe<Scalars['String']>
  allbatchesNicknameContains?: InputMaybe<Scalars['String']>
  directoryId?: InputMaybe<Scalars['ID']>
  entityId?: InputMaybe<Scalars['ID']>
  entityType?: InputMaybe<Scalars['Int']>
  highlightedArchived?: InputMaybe<Scalars['Boolean']>
  highlightedExcludeCreationSource?: InputMaybe<Scalars['String']>
  orderBatch?: InputMaybe<Scalars['String']>
  orderHighlight?: InputMaybe<Scalars['String']>
  pageType?: InputMaybe<PageType>
}

export type QueryNotificationSubscriptionStatusesArgs = {
  email?: InputMaybe<Scalars['String']>
}

export type QueryPlacementTemplateCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
}

export type QueryPlacementTemplateConfigArgs = {
  placementTemplateConfigId?: InputMaybe<Scalars['ID']>
}

export type QueryPlacementTemplateConfigGroupArgs = {
  placementTemplateConfigId?: InputMaybe<Scalars['ID']>
  productId?: InputMaybe<Scalars['String']>
}

export type QueryPlacementTemplateConfigsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  categoryId?: InputMaybe<Scalars['ID']>
  fetchAll?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type QueryPlacementTemplateConfigsByCategoryNameArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type QueryPresetLinkArgs = {
  batchId?: InputMaybe<Scalars['ID']>
}

export type QueryPrintStoreCategoryProductMappingsArgs = {
  after?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  maxProductsPerCategory?: InputMaybe<Scalars['Int']>
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryPrintStorePaginatedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  desc?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sortBy?: InputMaybe<Scalars['String']>
}

export type QueryPrintStoreProductArgs = {
  productId?: InputMaybe<Scalars['String']>
}

export type QueryPrintStoreProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  excludeChildCategories?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  parentCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryRedirectDomainsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>
}

export type QueryRedirectSubdomainPermissionsArgs = {
  subdomainIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>
}

export type QueryRootCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  fetchAll?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type QueryStudioConfigArgs = {
  configId?: InputMaybe<Scalars['ID']>
}

export type QueryStudioConfigEntityPermissionsArgs = {
  configId?: InputMaybe<Scalars['ID']>
}

export type QuerySubCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  fetchAll?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  parentCategoryId?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<Scalars['String']>
}

export type QueryTemplatePrintStoreProductsArgs = {
  templateId?: InputMaybe<Scalars['ID']>
}

export type QueryUsersArgs = {
  email_Startswith?: InputMaybe<Scalars['String']>
}

export type QueryUserSavedFlowcodeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

/** An enumeration. */
export enum RedirectType {
  CollectibleFp = 'COLLECTIBLE_FP',
  Email = 'EMAIL',
  Event = 'EVENT',
  Facebook = 'FACEBOOK',
  File = 'FILE',
  Flowpage = 'FLOWPAGE',
  GoogleDoc = 'GOOGLE_DOC',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  OneTimeUrl = 'ONE_TIME_URL',
  Phone = 'PHONE',
  PresetFp = 'PRESET_FP',
  ScanToActivate = 'SCAN_TO_ACTIVATE',
  ScanToOwn = 'SCAN_TO_OWN',
  Sms = 'SMS',
  Snapchat = 'SNAPCHAT',
  Social = 'SOCIAL',
  SocialMedia = 'SOCIAL_MEDIA',
  Spotify = 'SPOTIFY',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
  Url = 'URL',
  Vcard = 'VCARD',
  Venmo = 'VENMO',
  Wifi = 'WIFI',
  Youtube = 'YOUTUBE'
}

/**
 * Given subdomainId, attempts to check if the associated subdomain has been verified in cloudflare;
 * reflects any changes in verification status to the is_active field in WhiteLabelRedirectDomain.
 */
export type RefreshRedirectSubdomainStatus = {
  __typename?: 'RefreshRedirectSubdomainStatus'
  isActive?: Maybe<Scalars['Boolean']>
  ok?: Maybe<Scalars['Boolean']>
}

/** RenameAssetFile updates the name of any asset */
export type RenameAssetFile = {
  __typename?: 'RenameAssetFile'
  ok?: Maybe<Scalars['Boolean']>
}

/** RestoreBatch is the reverse of archive and makes a code active again */
export type RestoreBatch = {
  __typename?: 'RestoreBatch'
  batch?: Maybe<BatchDjangoType>
  link?: Maybe<LinkDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * RestoreExternalDomain attempts to restore a soft-deleted external domain. The operation will
 * fail if restoring the domain would result in exceeding the user/org's external domain limit,
 * or if the domain does not exist.
 */
export type RestoreExternalDomain = {
  __typename?: 'RestoreExternalDomain'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * Given subdomainId, restores a soft deleted subdomain in the domains service db. Sets is_active to True
 * on the WhiteLabelRedirectDomain object if the subdomain is cobranded.
 */
export type RestoreRedirectSubdomain = {
  __typename?: 'RestoreRedirectSubdomain'
  ok?: Maybe<Scalars['Boolean']>
}

export type RuleBasedObjectType = {
  __typename?: 'RuleBasedObjectType'
  batch: BatchDjangoType
  id: Scalars['UUID']
  interstitial?: Maybe<Scalars['JSONString']>
  lastModified: Scalars['DateTime']
  rule?: Maybe<Scalars['JSONString']>
  timeStamp: Scalars['DateTime']
}

export type SaveBrandKit = {
  __typename?: 'SaveBrandKit'
  ok: Scalars['Boolean']
}

export enum ScanToOwnLandingPages {
  Cpg = 'CPG',
  Designer = 'DESIGNER',
  ECommerce = 'E_COMMERCE',
  InfluencerBlack = 'INFLUENCER_BLACK',
  InfluencerPink = 'INFLUENCER_PINK',
  Restaurants = 'RESTAURANTS'
}

export type SendCollectBatchEmailInputType = {
  landingPage: ScanToOwnLandingPages
  toEmail: Scalars['String']
}

/** Mutation that handles the sharing of various assets. */
export type ShareIthacaAssets = {
  __typename?: 'ShareIthacaAssets'
  assetPermissions?: Maybe<Array<Maybe<UpdateIthacaPermissionsResultType>>>
}

export type ShippingOption = {
  __typename?: 'ShippingOption'
  currency?: Maybe<Scalars['String']>
  deliveryDaysRangeMax?: Maybe<Scalars['Int']>
  deliveryDaysRangeMin?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  doesSupportInternational?: Maybe<Scalars['Boolean']>
  estimatedDeliveryDateMax?: Maybe<Scalars['DateTime']>
  estimatedDeliveryDateMin?: Maybe<Scalars['DateTime']>
  excludedDeliveryDaysOfWeek?: Maybe<Array<Maybe<Scalars['Int']>>>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
}

/**
 * ShoppingCartAddItem adds an item to a shopping cart. If no cart exists it creates one.
 * Adds an entry into shopping cart history
 *
 * template_id represents the id of an internally created template.
 * dh_template_id is the id of a template in design huddles system.
 * Only one of these fields (or neither) should be provided
 *
 * If dh_template_id is provided, dh_project_id must be provided as well
 */
export type ShoppingCartAddItem = {
  __typename?: 'ShoppingCartAddItem'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * ShoppingCartDeleteItem removes an item from the shopping cart
 * Adds an entry into ShoppingCartHistory table
 */
export type ShoppingCartDeleteItem = {
  __typename?: 'ShoppingCartDeleteItem'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * ShoppingCartEditItemQuantity adjusts the quanity for a given item within a shopping cart
 * Adds an entry into ShoppingCartHistory table
 */
export type ShoppingCartEditItemQuantity = {
  __typename?: 'ShoppingCartEditItemQuantity'
  ok?: Maybe<Scalars['Boolean']>
}

export type ShoppingCartItem = {
  __typename?: 'ShoppingCartItem'
  batch?: Maybe<BatchDjangoType>
  dhProjectExportId?: Maybe<Scalars['String']>
  dhProjectId?: Maybe<Scalars['String']>
  dhTemplateId?: Maybe<Scalars['String']>
  itemId?: Maybe<Scalars['String']>
  product?: Maybe<Product>
  quantity?: Maybe<Scalars['Int']>
  template?: Maybe<PlacementTemplateConfigObjectType>
  templateDetails?: Maybe<Scalars['JSONString']>
}

/**
 * ShoppingCartOrderPlaced deletes the ShoppingCart object
 * Adds an entry into ShoppingCartHistory table
 * Does not send a request to payments client
 */
export type ShoppingCartOrderPlaced = {
  __typename?: 'ShoppingCartOrderPlaced'
  ok?: Maybe<Scalars['Boolean']>
}

export type ShoppingCartResponse = {
  __typename?: 'ShoppingCartResponse'
  countryCode?: Maybe<Scalars['String']>
  handlingOptions?: Maybe<Array<Maybe<HandlingOption>>>
  id?: Maybe<Scalars['String']>
  items?: Maybe<Array<Maybe<ShoppingCartItem>>>
  postalCode?: Maybe<Scalars['String']>
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>
}

/** An enumeration. */
export enum SourceType {
  Autodesign = 'AUTODESIGN',
  Diy = 'DIY',
  Partial = 'PARTIAL',
  Studio = 'STUDIO'
}

export type StudioConfigInputType = {
  addUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  assetFile?: InputMaybe<Scalars['UUID']>
  configId: Scalars['UUID']
  defaultAssociatedIcon?: InputMaybe<Scalars['Boolean']>
  defaultAssociatedIconName?: InputMaybe<Scalars['String']>
  displayToAll?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  newIthacaPlanIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  oldIthacaPlanIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  removeUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

export type StudioConfigObjectType = Node & {
  __typename?: 'StudioConfigObjectType'
  assetFile?: Maybe<AssetFileObjectType>
  configuration: Scalars['JSONString']
  creator?: Maybe<UserType>
  displayToAll: Scalars['Boolean']
  entityPermissions?: Maybe<Array<Maybe<EntityPermissionObjectType>>>
  /** The ID of the object. */
  id: Scalars['ID']
  isCustomizable: Scalars['Boolean']
  name: Scalars['String']
  publishedOn?: Maybe<Scalars['DateTime']>
  source?: Maybe<SourceType>
  timeStamp: Scalars['DateTime']
  users: Array<UserType>
}

export type StudioConfigObjectTypeEntityPermissionsArgs = {
  entityTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type StudioConfigObjectTypeConnection = {
  __typename?: 'StudioConfigObjectTypeConnection'
  edgeCount?: Maybe<Scalars['Int']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudioConfigObjectTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `StudioConfigObjectType` and its cursor. */
export type StudioConfigObjectTypeEdge = {
  __typename?: 'StudioConfigObjectTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<StudioConfigObjectType>
}

export type StudioObjectType = {
  __typename?: 'StudioObjectType'
  assets?: Maybe<AssetFileObjectTypeConnection>
  tag?: Maybe<StudioTagObjectType>
  tags?: Maybe<Array<Maybe<StudioTagObjectType>>>
  templates?: Maybe<StudioConfigObjectTypeConnection>
}

export type StudioObjectTypeAssetsArgs = {
  after?: InputMaybe<Scalars['String']>
  assetName?: InputMaybe<Scalars['String']>
  assetName_Icontains?: InputMaybe<Scalars['String']>
  assetName_Istartswith?: InputMaybe<Scalars['String']>
  assetType?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type StudioObjectTypeTagArgs = {
  name: Scalars['String']
}

export type StudioObjectTypeTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['UUID']>
  isCustomizable?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
  name_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  publishedOn_Isnull?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
}

export type StudioTagObjectType = {
  __typename?: 'StudioTagObjectType'
  assetFileSet: AssetFileObjectTypeConnection
  id: Scalars['UUID']
  name: Scalars['String']
}

export type StudioTagObjectTypeAssetFileSetArgs = {
  after?: InputMaybe<Scalars['String']>
  assetName?: InputMaybe<Scalars['String']>
  assetName_Icontains?: InputMaybe<Scalars['String']>
  assetName_Istartswith?: InputMaybe<Scalars['String']>
  assetType?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SubscriptionDetailType = {
  __typename?: 'SubscriptionDetailType'
  companySizeOptions?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>
  industryOptions?: Maybe<Array<Maybe<Scalars['String']>>>
  primaryFlowcodeUseOptions?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SubscriptionPlanType = {
  __typename?: 'SubscriptionPlanType'
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/**
 * SwitchBatchDesign changes which StudioConfig a batch points to
 * this is used when switching to a premade design i.e. BLM or Earth Day
 */
export type SwitchBatchDesign = {
  __typename?: 'SwitchBatchDesign'
  batch?: Maybe<BatchDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

export type Template = {
  __typename?: 'Template'
  dhTemplateId: Scalars['String']
  dimensionUnit: Scalars['String']
  height: Scalars['Float']
  name: Scalars['String']
  products?: Maybe<Array<TemplateProduct>>
  templateId: Scalars['UUID']
  width: Scalars['Float']
}

export type TemplateDetails = {
  __typename?: 'TemplateDetails'
  dimensionUnit: Scalars['String']
  height: Scalars['Float']
  templateId: Scalars['UUID']
  width: Scalars['Float']
}

export type TemplateProduct = {
  __typename?: 'TemplateProduct'
  categoryIds?: Maybe<Array<Scalars['UUID']>>
  name: Scalars['String']
  productId: Scalars['UUID']
}

export type Templates = {
  __typename?: 'Templates'
  pageInfo: PageInfo
  templates?: Maybe<Array<Template>>
}

/** An enumeration. */
export enum TileDisplayFormat {
  Normal = 'NORMAL',
  Wide = 'WIDE'
}

/**
 * This mutation is a custom endpoint to service the centre_bell stadium for one of our
 * enterprise clients. This endpoint is restricted for use by our CS team and will service
 * the 2023/2024 season
 */
export type ToggleCanadiensCodes = {
  __typename?: 'ToggleCanadiensCodes'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * TransferBatch updates the owner of the batch to be a different user
 * Creates an entry in BatchHistory to keep a log of owners
 */
export type TransferBatch = {
  __typename?: 'TransferBatch'
  batch?: Maybe<BatchDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/** TransferDirectory updates the owner of a directory. */
export type TransferDirectory = {
  __typename?: 'TransferDirectory'
  directory?: Maybe<DirectoryType>
  ok?: Maybe<Scalars['Boolean']>
}

/** Mutation that handles the unsharing of various assets. */
export type UnshareIthacaAssets = {
  __typename?: 'UnshareIthacaAssets'
  assetPermissions?: Maybe<Array<Maybe<UpdateIthacaPermissionsResultType>>>
}

/** UnsubscribeHubspot removes a user from our hubspot mailing list by sending a request to notifications */
export type UnsubscribeHubspot = {
  __typename?: 'UnsubscribeHubspot'
  ok?: Maybe<Scalars['Boolean']>
}

/** UpdateAssetTags creates an entry in the AssetFile_AssetTag for each tag supplied and the asset file */
export type UpdateAssetTags = {
  __typename?: 'UpdateAssetTags'
  assetFile?: Maybe<AssetFileObjectType>
  ok?: Maybe<Scalars['Boolean']>
}

/** UpdateBatchDirectory changes or removes the directory a batch is in */
export type UpdateBatchDirectory = {
  __typename?: 'UpdateBatchDirectory'
  batch?: Maybe<BatchDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/** UpdateBatchLink updates the batch and/or the batch link. */
export type UpdateBatchLink = {
  __typename?: 'UpdateBatchLink'
  batch?: Maybe<BatchDjangoType>
  link?: Maybe<LinkDjangoType>
  ok?: Maybe<Scalars['Boolean']>
}

/** UpdateBatchRules takes a rule and/or an interstitial config and updates them. It updates the CoreBatchRulesHistory table with an entry for each updated object. */
export type UpdateBatchRules = {
  __typename?: 'UpdateBatchRules'
  batchRule?: Maybe<RuleBasedObjectType>
  ok?: Maybe<Scalars['Boolean']>
}

export type UpdateCampaignName = {
  __typename?: 'UpdateCampaignName'
  ok?: Maybe<Scalars['Boolean']>
}

/** UpdateDirectory updates name and or parent id of a directory */
export type UpdateDirectory = {
  __typename?: 'UpdateDirectory'
  directory?: Maybe<DirectoryType>
  ok?: Maybe<Scalars['Boolean']>
}

export type UpdateIthacaPermissionsResultType = {
  __typename?: 'UpdateIthacaPermissionsResultType'
  assetId?: Maybe<Scalars['UUID']>
  result?: Maybe<IthacaPermissionsResultType>
}

/** UpdatePlcaementTemplateCategory updates a PlacementTemplateCategory */
export type UpdatePlacementTemplateCategory = {
  __typename?: 'UpdatePlacementTemplateCategory'
  ok?: Maybe<Scalars['Boolean']>
  placementTemplateCategory?: Maybe<PlacementTemplateCategoryObjectType>
}

/**
 * UpdatePlacementTemplateConfig updates a placement template config
 * setting toggle publish to true will remove the published date if it exists or sets it to now if it does not
 */
export type UpdatePlacementTemplateConfig = {
  __typename?: 'UpdatePlacementTemplateConfig'
  ok?: Maybe<Scalars['Boolean']>
  placementTemplateConfig?: Maybe<PlacementTemplateConfigObjectType>
}

/** UpdateSendgridSubscription modifies the subscription status of the user in Sendgrid */
export type UpdateSendgridSubscription = {
  __typename?: 'UpdateSendgridSubscription'
  ok?: Maybe<Scalars['Boolean']>
}

/**
 * UpdateStudioConfig updates the ithaca subscription plans, display_to_all value, users, or
 * asset_file column of an existing StudioConfig
 * Does not update the config column - the column that controls code design (see EditConfiguration)
 */
export type UpdateStudioConfig = {
  __typename?: 'UpdateStudioConfig'
  ok?: Maybe<Scalars['Boolean']>
  studioConfig?: Maybe<StudioConfigObjectType>
}

export type UserType = {
  __typename?: 'UserType'
  batches: Array<BatchOwnerDjangoType>
  canCreateStaticCode?: Maybe<Scalars['Boolean']>
  dateJoined: Scalars['DateTime']
  email: Scalars['String']
  firstName: Scalars['String']
  flowcodeCount?: Maybe<Scalars['Int']>
  flowcodes?: Maybe<BatchDjangoTypeConnection>
  flowtagCount?: Maybe<Scalars['Int']>
  /** Request user's name from Ithaca service. NOTE: Careful accessing this field within query requesting multiple objects */
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isFlowArmy?: Maybe<Scalars['Boolean']>
  ithacaId: Scalars['UUID']
  lastName: Scalars['String']
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']
}

export type UserTypeFlowcodesArgs = {
  after?: InputMaybe<Scalars['String']>
  archived?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  creationSource?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  nickname_Icontains?: InputMaybe<Scalars['String']>
  nickname_Istartswith?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type WhiteLabelDomainManagementType = {
  __typename?: 'WhiteLabelDomainManagementType'
  deletedAt?: Maybe<Scalars['DateTime']>
  domain?: Maybe<Scalars['String']>
  flowcodeSubdomains?: Maybe<Array<Maybe<WhiteLabelRedirectDomainType>>>
  flowpageSubdomains?: Maybe<Array<Maybe<WhiteLabelRedirectDomainType>>>
  id?: Maybe<Scalars['UUID']>
  type?: Maybe<Scalars['Int']>
}

export type WhiteLabelRedirectDomainType = {
  __typename?: 'WhiteLabelRedirectDomainType'
  deletedAt?: Maybe<Scalars['DateTime']>
  domain?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  isCoBranded?: Maybe<Scalars['Boolean']>
  isVerified?: Maybe<Scalars['Boolean']>
  productType?: Maybe<Scalars['Int']>
  timestamp?: Maybe<Scalars['DateTime']>
}

export type FolderAncestorsFragment = {
  __typename?: 'DirectoryType'
  id: string
  name: string
  batchCount?: number | null
}

export type CustomAssignedCodesFragment = {
  __typename?: 'StudioConfigObjectType'
  id: string
  name: string
  configuration: any
  isCustomizable: boolean
  assetFile?: { __typename?: 'AssetFileObjectType'; assetName: string; url?: string | null } | null
}

export type FlowcodeTemplatesFragment = {
  __typename?: 'StudioConfigObjectType'
  id: string
  name: string
  configuration: any
  isCustomizable: boolean
  assetFile?: { __typename?: 'AssetFileObjectType'; assetName: string; url?: string | null } | null
}

export type BatchTemplatesFragment = {
  __typename?: 'BatchDesignTemplateObjectType'
  id: string
  configuration: any
  name: string
}

export type BorderDesignConfigsFragment = {
  __typename?: 'BorderDesignObjectType'
  partialId?: any | null
  studioConfig?: {
    __typename?: 'StudioConfigObjectType'
    configuration: any
    name: string
    id: string
    assetFile?: {
      __typename?: 'AssetFileObjectType'
      assetName: string
      url?: string | null
    } | null
  } | null
}

export type UpdateSendgridSubscriptionMutationVariables = Exact<{
  email: Scalars['String']
  shouldUnsubscribe?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateSendgridSubscriptionMutation = {
  __typename?: 'Mutation'
  updateSendgridSubscription?: {
    __typename?: 'UpdateSendgridSubscription'
    ok?: boolean | null
  } | null
}

export type UnsubscribeHubspotMutationVariables = Exact<{
  email: Scalars['String']
}>

export type UnsubscribeHubspotMutation = {
  __typename?: 'Mutation'
  unsubscribeHubspot?: { __typename?: 'UnsubscribeHubspot'; ok?: boolean | null } | null
}

export type AddShoppingCartItemWithoutTemplateMutationVariables = Exact<{
  productId: Scalars['String']
  priceId: Scalars['String']
  quantity: Scalars['Int']
  batchId?: InputMaybe<Scalars['String']>
}>

export type AddShoppingCartItemWithoutTemplateMutation = {
  __typename?: 'Mutation'
  shoppingCartAddItem?: { __typename?: 'ShoppingCartAddItem'; ok?: boolean | null } | null
}

export type AddShoppingCartItemWithTemplateMutationVariables = Exact<{
  productId: Scalars['String']
  priceId: Scalars['String']
  quantity: Scalars['Int']
  batchId?: InputMaybe<Scalars['String']>
  templateId?: InputMaybe<Scalars['String']>
  templateDetails?: InputMaybe<Scalars['JSONString']>
}>

export type AddShoppingCartItemWithTemplateMutation = {
  __typename?: 'Mutation'
  shoppingCartAddItem?: { __typename?: 'ShoppingCartAddItem'; ok?: boolean | null } | null
}

export type AddShoppingCartItemWithDhTemplateMutationVariables = Exact<{
  batchId: Scalars['String']
  dhTemplateId: Scalars['String']
  dhProjectId: Scalars['String']
  dhProjectCustomization?: InputMaybe<Scalars['JSONString']>
  priceId: Scalars['String']
  productId: Scalars['String']
  quantity: Scalars['Int']
}>

export type AddShoppingCartItemWithDhTemplateMutation = {
  __typename?: 'Mutation'
  shoppingCartAddItem?: { __typename?: 'ShoppingCartAddItem'; ok?: boolean | null } | null
}

export type EditShoppingCartItemQtyMutationVariables = Exact<{
  itemId: Scalars['String']
  newQuantity: Scalars['Int']
}>

export type EditShoppingCartItemQtyMutation = {
  __typename?: 'Mutation'
  shoppingCartEditItemQuantity?: {
    __typename?: 'ShoppingCartEditItemQuantity'
    ok?: boolean | null
  } | null
}

export type DeleteShoppingCartItemMutationVariables = Exact<{
  itemId: Scalars['String']
}>

export type DeleteShoppingCartItemMutation = {
  __typename?: 'Mutation'
  shoppingCartDeleteItem?: { __typename?: 'ShoppingCartDeleteItem'; ok?: boolean | null } | null
}

export type ConfirmAndDeleteShoppingCartOrderMutationVariables = Exact<{
  cartId: Scalars['String']
}>

export type ConfirmAndDeleteShoppingCartOrderMutation = {
  __typename?: 'Mutation'
  shoppingCartOrderPlaced?: { __typename?: 'ShoppingCartOrderPlaced'; ok?: boolean | null } | null
}

export type CreatePrintStoreCheckoutMutationVariables = Exact<{
  checkoutData: CheckoutInput
}>

export type CreatePrintStoreCheckoutMutation = {
  __typename?: 'Mutation'
  printStoreCheckout?: {
    __typename?: 'PrintStoreCheckout'
    ok?: boolean | null
    stripeCheckoutSessionId?: string | null
  } | null
}

export type SavePrintStoreDhProjectMutationVariables = Exact<{
  dhProjectId: Scalars['String']
  dhUserId: Scalars['String']
  projectTitle: Scalars['String']
  sourceTemplateId: Scalars['UUID']
}>

export type SavePrintStoreDhProjectMutation = {
  __typename?: 'Mutation'
  associateProject?: { __typename?: 'AssociateProject'; ok: boolean } | null
}

export type SavePrintStoreBrandKitMutationVariables = Exact<{
  brandKit: BrandKitInput
}>

export type SavePrintStoreBrandKitMutation = {
  __typename?: 'Mutation'
  saveBrandKit?: { __typename?: 'SaveBrandKit'; ok: boolean } | null
}

export type ExportPrintStoreDhProjectMutationVariables = Exact<{
  dhProjectId: Scalars['String']
  exportMethod: ExportMethod
  dhProjectData: Scalars['JSONString']
  flowcodeId: Scalars['String']
}>

export type ExportPrintStoreDhProjectMutation = {
  __typename?: 'Mutation'
  projectExport?: { __typename?: 'CreateProjectExport'; ok: boolean } | null
}

export type CreateRedirectSubdomainMutationVariables = Exact<{
  subdomain: Scalars['String']
  rootDomainId: Scalars['String']
}>

export type CreateRedirectSubdomainMutation = {
  __typename?: 'Mutation'
  createRedirectSubdomain?: {
    __typename?: 'CreateRedirectSubdomain'
    ok?: boolean | null
    redirectSubdomain?: {
      __typename?: 'WhiteLabelRedirectDomainType'
      id?: any | null
      domain?: string | null
      timestamp?: any | null
      isVerified?: boolean | null
    } | null
  } | null
}

export type DeleteRedirectSubdomainMutationVariables = Exact<{
  subdomainId: Scalars['String']
}>

export type DeleteRedirectSubdomainMutation = {
  __typename?: 'Mutation'
  deleteRedirectSubdomain?: { __typename?: 'DeleteRedirectSubdomain'; ok?: boolean | null } | null
}

export type RestoreRedirectSubdomainMutationVariables = Exact<{
  subdomainId: Scalars['String']
}>

export type RestoreRedirectSubdomainMutation = {
  __typename?: 'Mutation'
  restoreRedirectSubdomain?: { __typename?: 'RestoreRedirectSubdomain'; ok?: boolean | null } | null
}

export type RefreshRedirectSubdomainStatusMutationVariables = Exact<{
  subdomainId: Scalars['String']
}>

export type RefreshRedirectSubdomainStatusMutation = {
  __typename?: 'Mutation'
  refreshRedirectSubdomainStatus?: {
    __typename?: 'RefreshRedirectSubdomainStatus'
    isActive?: boolean | null
  } | null
}

export type CreateExternalDomainMutationVariables = Exact<{
  domain: Scalars['String']
}>

export type CreateExternalDomainMutation = {
  __typename?: 'Mutation'
  createExternalDomain?: {
    __typename?: 'CreateExternalDomain'
    ok?: boolean | null
    externalDomain?: {
      __typename?: 'WhiteLabelRedirectDomainType'
      deletedAt?: any | null
      domain?: string | null
      id?: any | null
      isCoBranded?: boolean | null
      isVerified?: boolean | null
      productType?: number | null
      timestamp?: any | null
    } | null
  } | null
}

export type DeleteExternalDomainMutationVariables = Exact<{
  domainId: Scalars['String']
}>

export type DeleteExternalDomainMutation = {
  __typename?: 'Mutation'
  deleteExternalDomain?: { __typename?: 'DeleteExternalDomain'; ok?: boolean | null } | null
}

export type ShareIthacaAssetsMutationVariables = Exact<{
  assetType: Scalars['String']
  assetIds: Array<Scalars['UUID']> | Scalars['UUID']
  entityPermissions:
    | Array<InputMaybe<EntityPermissionInputType>>
    | InputMaybe<EntityPermissionInputType>
}>

export type ShareIthacaAssetsMutation = {
  __typename?: 'Mutation'
  shareIthacaAssets?: {
    __typename?: 'ShareIthacaAssets'
    assetPermissions?: Array<{
      __typename?: 'UpdateIthacaPermissionsResultType'
      assetId?: any | null
      result?: {
        __typename?: 'IthacaPermissionsResultType'
        success?: boolean | null
        error?: string | null
      } | null
    } | null> | null
  } | null
}

export type UnshareIthacaAssetsMutationVariables = Exact<{
  assetType: Scalars['String']
  assetIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>
  entityIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>
}>

export type UnshareIthacaAssetsMutation = {
  __typename?: 'Mutation'
  unshareIthacaAssets?: {
    __typename?: 'UnshareIthacaAssets'
    assetPermissions?: Array<{
      __typename?: 'UpdateIthacaPermissionsResultType'
      assetId?: any | null
      result?: {
        __typename?: 'IthacaPermissionsResultType'
        success?: boolean | null
        error?: string | null
      } | null
    } | null> | null
  } | null
}

export type TransferDirectoryMutationVariables = Exact<{
  directoryId: Scalars['ID']
  newOwnerEmail: Scalars['String']
}>

export type TransferDirectoryMutation = {
  __typename?: 'Mutation'
  transferDirectory?: { __typename?: 'TransferDirectory'; ok?: boolean | null } | null
}

export type SaveCodeTemplateMutationVariables = Exact<{
  input?: InputMaybe<BatchDesignTemplateInputType>
}>

export type SaveCodeTemplateMutation = {
  __typename?: 'Mutation'
  createBatchDesignTemplate?: {
    __typename?: 'CreateBatchDesignTemplate'
    ok?: boolean | null
    batchTemplate?: {
      __typename?: 'BatchDesignTemplateObjectType'
      id: string
      name: string
    } | null
  } | null
}

export type DeleteCodeTemplateMutationVariables = Exact<{
  templateId: Scalars['ID']
}>

export type DeleteCodeTemplateMutation = {
  __typename?: 'Mutation'
  deleteBatchDesignTemplate?: {
    __typename?: 'DeleteBatchDesignTemplate'
    ok?: boolean | null
  } | null
}

export type GetPresetLinkQueryVariables = Exact<{
  batchId?: InputMaybe<Scalars['ID']>
}>

export type GetPresetLinkQuery = {
  __typename?: 'Query'
  presetLink?: {
    __typename?: 'PresetLinkType'
    redirectValue?: string | null
    redirectType?: RedirectType | null
  } | null
}

export type GetIsCollectibleQueryVariables = Exact<{
  flowcodeId?: InputMaybe<Scalars['ID']>
  batchId?: InputMaybe<Scalars['ID']>
}>

export type GetIsCollectibleQuery = {
  __typename?: 'Query'
  collectible?: {
    __typename?: 'CollectibleResponseType'
    isCollectible?: boolean | null
    redirectType?: RedirectType | null
    redirectValue?: string | null
  } | null
}

export type GetflowtagsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
  creationSource?: InputMaybe<Scalars['String']>
}>

export type GetflowtagsQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'UserType'
    flowcodes?: {
      __typename?: 'BatchDjangoTypeConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        startCursor: string
        endCursor: string
        hasNextPage: boolean
      }
      edges: Array<{
        __typename?: 'BatchDjangoTypeEdge'
        node?: {
          __typename?: 'BatchDjangoType'
          id: string
          nickname: string
          activeLink?: {
            __typename?: 'LinkDjangoType'
            redirectValue: string
            shorturl: string
          } | null
        } | null
      } | null>
    } | null
  } | null
}

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetUserInfoQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'UserType'; flowtagCount?: number | null } | null
}

export type GetAssetLabelsQueryVariables = Exact<{
  isRecommended?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name_Icontains?: InputMaybe<Scalars['String']>
}>

export type GetAssetLabelsQuery = {
  __typename?: 'Query'
  assetLabels?: {
    __typename?: 'AssetLabelTypeConnection'
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string }
    edges: Array<{
      __typename?: 'AssetLabelTypeEdge'
      node?: { __typename?: 'AssetLabelType'; id: string; name: string } | null
    } | null>
  } | null
}

export type GetFolderAncestorsQueryVariables = Exact<{
  directoryId: Scalars['ID']
}>

export type GetFolderAncestorsQuery = {
  __typename?: 'Query'
  directory?: {
    __typename?: 'DirectoryType'
    id: string
    name: string
    batchCount?: number | null
    ancestors?: Array<{
      __typename?: 'DirectoryType'
      id: string
      name: string
      batchCount?: number | null
    } | null> | null
  } | null
}

export type GetFolderChildrenQueryVariables = Exact<{
  parentDirectoryId?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
}>

export type GetFolderChildrenQuery = {
  __typename?: 'Query'
  directories?: {
    __typename?: 'DirectoryManagementType'
    allDirs?: {
      __typename?: 'DirectoryConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string
        endCursor: string
      }
      edges: Array<{
        __typename?: 'DirectoryEdge'
        node?: {
          __typename?: 'DirectoryType'
          name: string
          id: string
          descendantCount?: number | null
          parent?: { __typename?: 'DirectoryType'; name: string; id: string } | null
        } | null
      } | null>
    } | null
  } | null
}

export type ShoppingCartQueryVariables = Exact<{ [key: string]: never }>

export type ShoppingCartQuery = {
  __typename?: 'Query'
  shoppingCart?: {
    __typename?: 'ShoppingCartResponse'
    id?: string | null
    countryCode?: string | null
    postalCode?: string | null
    items?: Array<{
      __typename?: 'ShoppingCartItem'
      itemId?: string | null
      quantity?: number | null
      templateDetails?: any | null
      dhProjectExportId?: string | null
      dhProjectId?: string | null
      dhTemplateId?: string | null
      product?: {
        __typename?: 'Product'
        id?: string | null
        name?: string | null
        description?: string | null
        imageUrls?: Array<string | null> | null
        availableQuantity?: number | null
        minOrderQuantity?: number | null
        maxOrderQuantity?: number | null
        orderQuantityIncrement?: number | null
        handlingTimeBusinessDays?: number | null
        shippingOptionIds?: Array<string | null> | null
        currency?: string | null
        priceId?: string | null
        priceModel?: {
          __typename?: 'PriceModel'
          priceType?: PriceType | null
          sortedTiers?: Array<{
            __typename?: 'PriceTier'
            quantity?: number | null
            unitAmount?: number | null
          } | null> | null
        } | null
      } | null
      batch?: {
        __typename?: 'BatchDjangoType'
        timeStamp: any
        lastModified: any
        id: string
        domain: string
        archived: boolean
        type: BatchType
        nickname: string
        creationSource?: BatchCreationSource | null
        studioConfig?: {
          __typename?: 'StudioConfigObjectType'
          id: string
          configuration: any
        } | null
        activeLink?: { __typename?: 'LinkDjangoType'; shorturl: string } | null
      } | null
      template?: { __typename?: 'PlacementTemplateConfigObjectType'; id: string } | null
    } | null> | null
    shippingOptions?: Array<{
      __typename?: 'ShippingOption'
      id?: string | null
      name?: string | null
      description?: string | null
      doesSupportInternational?: boolean | null
      currency?: string | null
      price?: number | null
      estimatedDeliveryDateMin?: any | null
      estimatedDeliveryDateMax?: any | null
      deliveryDaysRangeMin?: number | null
      deliveryDaysRangeMax?: number | null
      excludedDeliveryDaysOfWeek?: Array<number | null> | null
    } | null> | null
    handlingOptions?: Array<{
      __typename?: 'HandlingOption'
      id?: string | null
      name?: string | null
      description?: string | null
      productionDaysRangeMin?: number | null
      productionDaysRangeMax?: number | null
      pricePercentageIncrease?: number | null
    } | null> | null
  } | null
}

export type GetPrintStoreProductsQueryVariables = Exact<{
  batchId: Scalars['ID']
}>

export type GetPrintStoreProductsQuery = {
  __typename?: 'Query'
  printStoreProducts?: {
    __typename?: 'ProductsResponse'
    products?: Array<{
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      imageUrls?: Array<string | null> | null
      minOrderQuantity?: number | null
      availableQuantity?: number | null
      priceModel?: {
        __typename?: 'PriceModel'
        priceType?: PriceType | null
        sortedTiers?: Array<{
          __typename?: 'PriceTier'
          quantity?: number | null
          unitAmount?: number | null
        } | null> | null
      } | null
    } | null> | null
  } | null
  batch?: { __typename?: 'BatchDjangoType'; id: string; nickname: string } | null
}

export type GetGenericPrintStoreProductsQueryVariables = Exact<{ [key: string]: never }>

export type GetGenericPrintStoreProductsQuery = {
  __typename?: 'Query'
  printStoreProducts?: {
    __typename?: 'ProductsResponse'
    products?: Array<{
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      imageUrls?: Array<string | null> | null
      minOrderQuantity?: number | null
      availableQuantity?: number | null
      priceModel?: {
        __typename?: 'PriceModel'
        priceType?: PriceType | null
        sortedTiers?: Array<{
          __typename?: 'PriceTier'
          quantity?: number | null
          unitAmount?: number | null
        } | null> | null
      } | null
    } | null> | null
  } | null
}

export type PrintStoreProductWithBatchQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['String']>
  batchId?: InputMaybe<Scalars['ID']>
}>

export type PrintStoreProductWithBatchQuery = {
  __typename?: 'Query'
  printStoreProduct?: {
    __typename?: 'ProductResponse'
    product?: {
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      headerText?: string | null
      description?: string | null
      isCustomizable?: boolean | null
      imageUrls?: Array<string | null> | null
      minOrderQuantity?: number | null
      maxOrderQuantity?: number | null
      orderQuantityIncrement?: number | null
      availableQuantity?: number | null
      handlingTimeBusinessDays?: number | null
      currency?: string | null
      priceId?: string | null
      priceModel?: {
        __typename?: 'PriceModel'
        priceType?: PriceType | null
        sortedTiers?: Array<{
          __typename?: 'PriceTier'
          quantity?: number | null
          unitAmount?: number | null
        } | null> | null
      } | null
    } | null
    shippingOptions?: Array<{
      __typename?: 'ShippingOption'
      id?: string | null
      name?: string | null
      description?: string | null
      deliveryDaysRangeMin?: number | null
      deliveryDaysRangeMax?: number | null
      excludedDeliveryDaysOfWeek?: Array<number | null> | null
      doesSupportInternational?: boolean | null
      currency?: string | null
      price?: number | null
    } | null> | null
  } | null
  batch?: {
    __typename?: 'BatchDjangoType'
    id: string
    nickname: string
    activeLink?: {
      __typename?: 'LinkDjangoType'
      shorturl: string
      redirectValue: string
      redirectType: LinkRedirectType
    } | null
    studioConfig?: { __typename?: 'StudioConfigObjectType'; id: string; configuration: any } | null
  } | null
}

export type PrintStoreProductQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['String']>
}>

export type PrintStoreProductQuery = {
  __typename?: 'Query'
  printStoreProduct?: {
    __typename?: 'ProductResponse'
    product?: {
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      headerText?: string | null
      description?: string | null
      isCustomizable?: boolean | null
      imageUrls?: Array<string | null> | null
      minOrderQuantity?: number | null
      maxOrderQuantity?: number | null
      orderQuantityIncrement?: number | null
      availableQuantity?: number | null
      handlingTimeBusinessDays?: number | null
      currency?: string | null
      priceId?: string | null
      priceModel?: {
        __typename?: 'PriceModel'
        priceType?: PriceType | null
        sortedTiers?: Array<{
          __typename?: 'PriceTier'
          quantity?: number | null
          unitAmount?: number | null
        } | null> | null
      } | null
    } | null
    shippingOptions?: Array<{
      __typename?: 'ShippingOption'
      id?: string | null
      name?: string | null
      description?: string | null
      deliveryDaysRangeMin?: number | null
      deliveryDaysRangeMax?: number | null
      excludedDeliveryDaysOfWeek?: Array<number | null> | null
      doesSupportInternational?: boolean | null
      currency?: string | null
      price?: number | null
    } | null> | null
  } | null
}

export type GetPrintStoreDiscountsQueryVariables = Exact<{ [key: string]: never }>

export type GetPrintStoreDiscountsQuery = {
  __typename?: 'Query'
  printStoreDiscountsByPlan?: {
    __typename?: 'AccountDiscounts'
    freeDiscount?: { __typename?: 'Discount'; percent?: number | null } | null
    proDiscount?: { __typename?: 'Discount'; percent?: number | null } | null
    proplusDiscount?: { __typename?: 'Discount'; percent?: number | null } | null
    proflexDiscount?: { __typename?: 'Discount'; percent?: number | null } | null
    enterpriseDiscount?: { __typename?: 'Discount'; percent?: number | null } | null
  } | null
}

export type EstimatedDeliveryDateQueryVariables = Exact<{
  handlingOptionId: Scalars['ID']
  shippingOptionId: Scalars['ID']
}>

export type EstimatedDeliveryDateQuery = {
  __typename?: 'Query'
  estimatedDeliveryDate?: {
    __typename?: 'EstimatedDeliveryDateResponse'
    estimatedDeliveryDateMin?: any | null
    estimatedDeliveryDateMax?: any | null
  } | null
}

export type GetDomainManagementDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetDomainManagementDetailsQuery = {
  __typename?: 'Query'
  domainManagementDetails?: Array<{
    __typename?: 'WhiteLabelDomainManagementType'
    id?: any | null
    domain?: string | null
    type?: number | null
    deletedAt?: any | null
    flowcodeSubdomains?: Array<{
      __typename?: 'WhiteLabelRedirectDomainType'
      id?: any | null
      domain?: string | null
      timestamp?: any | null
      isVerified?: boolean | null
      deletedAt?: any | null
    } | null> | null
    flowpageSubdomains?: Array<{
      __typename?: 'WhiteLabelRedirectDomainType'
      id?: any | null
      domain?: string | null
      timestamp?: any | null
      isVerified?: boolean | null
      deletedAt?: any | null
    } | null> | null
  } | null> | null
}

export type FlowpageFlowcodeDownloadDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type FlowpageFlowcodeDownloadDetailsQuery = {
  __typename?: 'Query'
  batch?: {
    __typename?: 'BatchDjangoType'
    archived: boolean
    studioConfig?: { __typename?: 'StudioConfigObjectType'; id: string; configuration: any } | null
    activeLink?: { __typename?: 'LinkDjangoType'; shorturl: string } | null
  } | null
}

export type WhiteLabelRedirectDomainsQueryVariables = Exact<{ [key: string]: never }>

export type WhiteLabelRedirectDomainsQuery = {
  __typename?: 'Query'
  whiteLabelRedirectDomains?: Array<{
    __typename?: 'WhiteLabelRedirectDomainType'
    domain?: string | null
    id?: any | null
    isCoBranded?: boolean | null
    timestamp?: any | null
  } | null> | null
}

export type GetRedirectSubdomainPermissionsQueryVariables = Exact<{
  subdomainIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>>
}>

export type GetRedirectSubdomainPermissionsQuery = {
  __typename?: 'Query'
  redirectSubdomainPermissions?: Array<{
    __typename?: 'FetchIthacaPermissionsResultType'
    assetId?: any | null
    result?: {
      __typename?: 'IthacaPermissionsResultType'
      success?: boolean | null
      error?: string | null
    } | null
    entityPermissions?: Array<{
      __typename?: 'EntityPermissionObjectType'
      entityId?: string | null
      entityType?: string | null
      permissionName?: string | null
      entityName?: string | null
    } | null> | null
  } | null> | null
}

export type GetBatchesForSubdomainsQueryVariables = Exact<{
  subdomainIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>>
}>

export type GetBatchesForSubdomainsQuery = {
  __typename?: 'Query'
  batchesForSubdomains?: Array<{ __typename?: 'BatchDjangoType'; id: string } | null> | null
}

export type FlowcodeCountQueryVariables = Exact<{ [key: string]: never }>

export type FlowcodeCountQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'UserType'; flowcodeCount?: number | null } | null
}

export type PrintStorePaginatedProductsQueryVariables = Exact<{
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  productCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  limit?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
}>

export type PrintStorePaginatedProductsQuery = {
  __typename?: 'Query'
  printStorePaginatedProducts?: {
    __typename?: 'PaginatedProductsResponse'
    products?: Array<{
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      imageUrls?: Array<string | null> | null
      priceModel?: {
        __typename?: 'PriceModel'
        sortedTiers?: Array<{
          __typename?: 'PriceTier'
          quantity?: number | null
          unitAmount?: number | null
        } | null> | null
      } | null
    } | null> | null
    paginationInfo?: {
      __typename?: 'PaginationInfo'
      hasPreviousPage?: boolean | null
      hasNextPage?: boolean | null
      startCursor?: string | null
      endCursor?: string | null
    } | null
  } | null
}

export type PrintStoreProductCategoriesListQueryVariables = Exact<{
  productCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  parentCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  limit?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
  excludeChildCategories?: InputMaybe<Scalars['Boolean']>
}>

export type PrintStoreProductCategoriesListQuery = {
  __typename?: 'Query'
  printStoreProductCategories?: {
    __typename?: 'ProductCategoriesResponse'
    productCategories?: Array<{
      __typename?: 'ProductCategory'
      id?: string | null
      name?: string | null
      description?: string | null
      tileImageUrl?: string | null
      isTopLevelCategory?: boolean | null
    } | null> | null
    paginationInfo?: {
      __typename?: 'PaginationInfo'
      hasPreviousPage?: boolean | null
      hasNextPage?: boolean | null
      startCursor?: string | null
      endCursor?: string | null
    } | null
  } | null
}

export type PrintStoreProductSubCategoryIdsQueryVariables = Exact<{
  parentCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type PrintStoreProductSubCategoryIdsQuery = {
  __typename?: 'Query'
  printStoreProductCategories?: {
    __typename?: 'ProductCategoriesResponse'
    productCategories?: Array<{ __typename?: 'ProductCategory'; id?: string | null } | null> | null
  } | null
}

export type PrintStoreProductTopCategoryQueryVariables = Exact<{
  productCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type PrintStoreProductTopCategoryQuery = {
  __typename?: 'Query'
  printStoreProductCategories?: {
    __typename?: 'ProductCategoriesResponse'
    productCategories?: Array<{
      __typename?: 'ProductCategory'
      id?: string | null
      name?: string | null
      productBannerImageUrls?: Array<string | null> | null
      productBannerText?: string | null
    } | null> | null
  } | null
}

export type PrintStoreProductTemplatesBannerQueryVariables = Exact<{
  productCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
}>

export type PrintStoreProductTemplatesBannerQuery = {
  __typename?: 'Query'
  printStoreProductCategories?: {
    __typename?: 'ProductCategoriesResponse'
    productCategories?: Array<{
      __typename?: 'ProductCategory'
      id?: string | null
      name?: string | null
      templateBannerImageUrls?: Array<string | null> | null
      templateBannerText?: string | null
    } | null> | null
  } | null
}

export type PrintStoreCategoryProductsListQueryVariables = Exact<{
  productCategoryIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  maxProductsPerCategory?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
}>

export type PrintStoreCategoryProductsListQuery = {
  __typename?: 'Query'
  printStoreCategoryProductMappings?: {
    __typename?: 'CategoryProductMappingsResponse'
    categoryProductMappings?: Array<{
      __typename?: 'ProductCategoryWithProducts'
      id?: string | null
      name?: string | null
      hasMoreProducts?: boolean | null
      products?: Array<{
        __typename?: 'Product'
        id?: string | null
        name?: string | null
        imageUrls?: Array<string | null> | null
        isCustomizable?: boolean | null
        priceModel?: {
          __typename?: 'PriceModel'
          sortedTiers?: Array<{
            __typename?: 'PriceTier'
            quantity?: number | null
            unitAmount?: number | null
          } | null> | null
        } | null
      } | null> | null
    } | null> | null
    paginationInfo?: {
      __typename?: 'PaginationInfo'
      hasPreviousPage?: boolean | null
      hasNextPage?: boolean | null
      startCursor?: string | null
      endCursor?: string | null
    } | null
  } | null
}

export type PrintStoreBrandKitQueryVariables = Exact<{ [key: string]: never }>

export type PrintStoreBrandKitQuery = {
  __typename?: 'Query'
  getBrandKit?: {
    __typename?: 'BrandKit'
    primaryColor: string
    secondaryColor: string
    primaryText: string
    logoImage: string
  } | null
}

export type PrintStoreSingleTemplateQueryVariables = Exact<{
  templateId: Scalars['UUID']
}>

export type PrintStoreSingleTemplateQuery = {
  __typename?: 'Query'
  getTemplate?: {
    __typename?: 'Template'
    templateId: any
    dhTemplateId: string
    name: string
    width: number
    height: number
    dimensionUnit: string
  } | null
}

export type PrintStoreMultipleTemplatesQueryVariables = Exact<{
  productId?: InputMaybe<Scalars['UUID']>
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>>
  layouts?: InputMaybe<Array<InputMaybe<Layout>> | InputMaybe<Layout>>
  fetchProducts?: InputMaybe<Scalars['Boolean']>
  limit: Scalars['Int']
  after: Scalars['String']
}>

export type PrintStoreMultipleTemplatesQuery = {
  __typename?: 'Query'
  getTemplates?: {
    __typename?: 'Templates'
    templates?: Array<{
      __typename?: 'Template'
      templateId: any
      dhTemplateId: string
      name: string
      height: number
      width: number
      dimensionUnit: string
      products?: Array<{
        __typename?: 'TemplateProduct'
        productId: any
        name: string
        categoryIds?: Array<any> | null
      }> | null
    }> | null
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      endCursor: string
    }
  } | null
}

export type PrintStorePreviewImageUrlQueryVariables = Exact<{
  dhProjectId: Scalars['String']
  format?: InputMaybe<ExportFormat>
}>

export type PrintStorePreviewImageUrlQuery = {
  __typename?: 'Query'
  getImageUrl?: { __typename?: 'ImageDetails'; imageUrl: string } | null
}

export type PrintStoreTempPreviewImageUrlQueryVariables = Exact<{
  dhProjectId: Scalars['String']
  format?: InputMaybe<ExportFormat>
}>

export type PrintStoreTempPreviewImageUrlQuery = {
  __typename?: 'Query'
  getTemporaryImageUrl?: { __typename?: 'ImageDetails'; imageUrl: string } | null
}

export type PrintStoreCategoriesQueryVariables = Exact<{
  after: Scalars['String']
  limit: Scalars['Int']
}>

export type PrintStoreCategoriesQuery = {
  __typename?: 'Query'
  getCategories?: {
    __typename?: 'Categories'
    categories?: Array<{
      __typename?: 'Category'
      categoryId: any
      categoryName: string
      sectionName: string
    }> | null
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      endCursor: string
    }
  } | null
}

export type CartPopularItemsProductsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
}>

export type CartPopularItemsProductsListQuery = {
  __typename?: 'Query'
  printStorePaginatedProducts?: {
    __typename?: 'PaginatedProductsResponse'
    products?: Array<{
      __typename?: 'Product'
      id?: string | null
      name?: string | null
      headerText?: string | null
      imageUrls?: Array<string | null> | null
      isCustomizable?: boolean | null
      categoryIds?: Array<string | null> | null
    } | null> | null
  } | null
}

export type GetTemplatesBrandKitFlowcodeDetailsQueryVariables = Exact<{
  batchId: Scalars['ID']
}>

export type GetTemplatesBrandKitFlowcodeDetailsQuery = {
  __typename?: 'Query'
  batch?: {
    __typename?: 'BatchDjangoType'
    id: string
    nickname: string
    type: BatchType
    domain: string
    lastModified: any
    timeStamp: any
    activeLink?: {
      __typename?: 'LinkDjangoType'
      id: any
      redirectValue: string
      shorturl: string
      redirectType: LinkRedirectType
    } | null
    analytics?: { __typename?: 'AnalyticsType'; lastScanDate?: any | null } | null
    studioConfig?: {
      __typename?: 'StudioConfigObjectType'
      id: string
      configuration: any
      isCustomizable: boolean
      source?: SourceType | null
      name: string
    } | null
    batchOwner?: {
      __typename?: 'BatchOwnerDjangoType'
      isOwner?: boolean | null
      user?: { __typename?: 'UserType'; ithacaId: any; fullName?: string | null } | null
    } | null
  } | null
}

export type CodeManagementQueryVariables = Exact<{
  allbatchesArchived?: InputMaybe<Scalars['Boolean']>
  pageType?: InputMaybe<PageType>
}>

export type CodeManagementQuery = {
  __typename?: 'Query'
  myFlowcodes?: {
    __typename?: 'CodeManagementType'
    allBatches?: { __typename?: 'BatchConnection'; totalCount?: number | null } | null
  } | null
}

export type DisplayToAllFlowcodeTemplatesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  nameIstartswith?: InputMaybe<Scalars['String']>
  nameIcontains?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  publishedOnIsnull?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
}>

export type DisplayToAllFlowcodeTemplatesQuery = {
  __typename?: 'Query'
  defaultFlowcodeTemplates?: {
    __typename?: 'StudioConfigObjectTypeConnection'
    totalCount?: number | null
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      endCursor: string
    }
    edges: Array<{
      __typename?: 'StudioConfigObjectTypeEdge'
      cursor: string
      node?: {
        __typename?: 'StudioConfigObjectType'
        id: string
        name: string
        configuration: any
        displayToAll: boolean
        isCustomizable: boolean
        source?: SourceType | null
      } | null
    } | null>
  } | null
}

export type GetAssignedFlowcodeTemplatesQueryVariables = Exact<{
  entityTypesFilter?: InputMaybe<Array<InputMaybe<EntityTypes>> | InputMaybe<EntityTypes>>
  includeDefaultTemplates?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  nameIstartswith?: InputMaybe<Scalars['String']>
  nameIcontains?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  publishedOnIsNull?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  isCustomizable?: InputMaybe<Scalars['Boolean']>
}>

export type GetAssignedFlowcodeTemplatesQuery = {
  __typename?: 'Query'
  assignedFlowcodeTemplates?: {
    __typename?: 'StudioConfigObjectTypeConnection'
    totalCount?: number | null
    edges: Array<{
      __typename?: 'StudioConfigObjectTypeEdge'
      cursor: string
      node?: {
        __typename?: 'StudioConfigObjectType'
        id: string
        name: string
        configuration: any
        assetFile?: { __typename?: 'AssetFileObjectType'; id: string; assetName: string } | null
      } | null
    } | null>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      endCursor: string
    }
  } | null
}

export type GetUserSavedFlowcodeTemplatesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}>

export type GetUserSavedFlowcodeTemplatesQuery = {
  __typename?: 'Query'
  userSavedFlowcodeTemplates?: {
    __typename?: 'BatchDesignTemplateObjectTypeConnection'
    totalCount?: number | null
    edges: Array<{
      __typename?: 'BatchDesignTemplateObjectTypeEdge'
      cursor: string
      node?: {
        __typename?: 'BatchDesignTemplateObjectType'
        id: string
        name: string
        configuration: any
        timeStamp: any
      } | null
    } | null>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string
      endCursor: string
    }
  } | null
}

export type AllNestedDirectoriesQueryVariables = Exact<{ [key: string]: never }>

export type AllNestedDirectoriesQuery = {
  __typename?: 'Query'
  allNestedDirectories?: {
    __typename?: 'NestedDirectoriesDetailType'
    nestedDirs?: Array<{
      __typename?: 'NestedDirectoryDetailType'
      name?: string | null
      id?: number | null
      isRoot?: boolean | null
      children?: Array<{
        __typename?: 'DirectoryDetailType'
        name?: string | null
        id?: number | null
      } | null> | null
    } | null> | null
  } | null
}

export type GetFlowcodeTemplatesCountQueryVariables = Exact<{
  entityTypesFilter?: InputMaybe<Array<InputMaybe<EntityTypes>> | InputMaybe<EntityTypes>>
  includeDefaultTemplates?: InputMaybe<Scalars['Boolean']>
  isCustomizable?: InputMaybe<Scalars['Boolean']>
}>

export type GetFlowcodeTemplatesCountQuery = {
  __typename?: 'Query'
  userSavedFlowcodeTemplates?: {
    __typename?: 'BatchDesignTemplateObjectTypeConnection'
    totalCount?: number | null
  } | null
  assignedFlowcodeTemplates?: {
    __typename?: 'StudioConfigObjectTypeConnection'
    totalCount?: number | null
  } | null
}
