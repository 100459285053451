import { ANONYMOUS_SESSION_ID } from '@dtx-company/true-common/src/constants/sessionId'
import {
  AUTO_DESIGNS_BRAND_DATA_QUERY,
  CREATE_FLOWCODE_FROM_URL_QUERY
} from '../gql/queries/flowcode'
import { AssetTypes } from '../types/asset-types.types'
import {
  BEST_PRACTICES_PDF_PATH,
  DOWNLOAD_BEST_PRACTICES_PDF_EXPERIENCE
} from '../constants/code-management'
import {
  BULK_UPDATE_BATCH_DIRECTORY_MUTATION,
  COLLECT_BATCH_MUTATION,
  CREATE_BATCH_RULE_MUTATION,
  CREATE_COLLECTIBLE_BATCH_MUTATION,
  CREATE_FLOWCODE_MUTATION,
  CREATE_SAVE_TEMPLATE_MUTATION,
  DELETE_BATCH_RULES_MUTATION,
  EDIT_BATCH_CONFIG_MUTATION,
  PRESET_COLLECT_BATCH_MUTATION,
  SEND_STO_EMAIL_MUTATION,
  SWITCH_BATCH_MUTATION,
  TRANSFER_BATCH_MUTATION,
  UPDATE_BATCH_DIRECTORY_MUTATION,
  UPDATE_BATCH_RULE_MUTATION,
  UPDATE_FLOWCODE_MUTATION
} from '../gql/mutations/flowcode'
import {
  BatchDesignTemplateInputType,
  EditBatchDesign,
  MutationCreateBatchArgs,
  MutationDeleteBatchRulesArgs,
  QueryAutoDesignsFromBrandDataArgs,
  SwitchBatchDesign
} from '@dtx-company/flow-codegen/src/code/generated.types'
import {
  BulkUpdateBatchDirectoryVariablesType,
  CollectBatchResponseType,
  CollectBatchVariablesType,
  CreateBatchResponseType,
  CreateBatchRuleRequestType,
  CreateCollectibleBatchRequestType,
  CreateFlowcodeBulkProps,
  CreateFlowcodeBulkResponseType,
  CreateFlowcodeFromURLRequestType,
  CreateFlowcodeRequestType,
  CreateStudioConfigVariablesType,
  DownloadEducationTemplateType,
  DownloadFolderInfoCsvProps,
  EducationTemplateDownloadOptionMimeType,
  EmailEducationTemplateType,
  EncodedDataType,
  FolderLinksDownloadOptionMimeType,
  GetNewStudioConfigIdType,
  HandleCollectBatchArgs,
  HandleDownloadFlowcodeType,
  PresetCollectBatchResponseType,
  STOEmailRequestType,
  TransferBatchRequestType,
  UpdateBatchDirectoryVariablesType,
  UpdateFlowcodeVariablesType,
  ValidateFlowcodeBulkResponseType
} from '../types/flowcode.types'
import { CREATE_STUDIO_CONFIG_MUTATION } from '../gql/mutations/studio'
import { DEFAULT_DPI } from '@dtx-company/flowcode-generator-browser/src/generateFlowcode.constant'
import {
  DEFAULT_FLOWCODE_DESTINATION,
  DEFAULT_FLOWCODE_DESTINATION_LOGGED_IN,
  DEFAULT_FLOWCODE_DESTINATION_PREFIX,
  DEFAULT_FLOWCODE_LOGO_DIMENSIONS,
  UNSAFE_FILE_UPLOAD_ERROR
} from '../constants/flowcode'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-browser/src'
import {
  FlowcodeDownloadOptionMimeType,
  FlowcodeTemplateType,
  FlowcodeType,
  LandingPageDestination,
  LandingPageDestinationType,
  StudioConfigSourceType
} from '@dtx-company/inter-app/src/types/flowcode'
import { ImageDimensionType } from '../types/image.types'
import { LANDING_PAGE_DESTINATIONS } from '../constants/landing-page'
import { StudioFlowcodeDownloadOptionMimeType } from '@dtx-company/true-common/src/types/studio'
import { addDismissedExperience } from '@app/page/src/utils/dismissedExperiences'
import { downloadFlowcode } from './downloadFlowcode'
import {
  generateRandomString,
  getIdFromEncodedString
} from '@dtx-company/true-common/src/utils/strings'
import { getFlowcodeGeneratorRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { getGqlError } from './errors'
import { getImageDimensions } from './images'
import { gqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { isStaticCode } from './flowcode/isStaticCode'
import { isStaticRedirectType } from './flowcode/isStaticRedirectType'
import {
  resetFolderLinksDownloadJustOccurred,
  setFolderLinksDownloadJustOccurred,
  toggleFolderLinksIsDownloadInProgress
} from '@dtx-company/inter-app/src/redux/slices/code-management-slice'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'
import { trackHeapEvent } from './heap'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'universal-cookie'
import config from '@dtx-company/true-common/src/constants/config'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowcode'

const cookies = new Cookies()

interface WiFiRedirectObject {
  name?: string
  password?: string
  noPassword?: boolean
  passwordType?: string
}

export const getWiFiRedirectValue = (redirectValue?: string): WiFiRedirectObject | undefined => {
  const regExpName = 'S:(.*?);'
  const regExpNoPassword = 'H:(.*?);'
  const regExpPassword = 'P:(.*?);'
  const regExpPasswordType = 'T:(.*?);'
  const findValue = (regExp: string): string | undefined => redirectValue?.match(regExp)?.[1]
  return {
    name: findValue(regExpName),
    noPassword: findValue(regExpNoPassword) === 'true',
    password: findValue(regExpPassword),
    passwordType: findValue(regExpPasswordType)
  }
}

export const fileReaderDownload = (
  filename: string,
  targetFileType:
    | (FlowcodeDownloadOptionMimeType | StudioFlowcodeDownloadOptionMimeType)
    | EducationTemplateDownloadOptionMimeType
    | FolderLinksDownloadOptionMimeType,
  blob: Blob
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        const a = document.createElement('a')
        a.href = reader.result as string
        a.download = `${filename}.${targetFileType}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        return resolve()
      }
      reject()
    }
    if (targetFileType === 'pdf') blob = blob.slice(0, blob.size, 'application/octet-stream')
    reader.readAsDataURL(blob)
  })
}

export const mobileShare = async (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const nav: any = navigator
      nav
        .share({
          title: 'Flowcode',
          url: url
        })
        .then(resolve())
        .catch((e: any) => {
          reject(e)
        })
    } catch (e) {
      reject(e)
    }
  })
}

export const downloadBestPracticesPdf = (): void => {
  const a = document.createElement('a')
  a.href = BEST_PRACTICES_PDF_PATH
  a.setAttribute('download', 'Best Practices Flowcode')
  a.click()
  addDismissedExperience(DOWNLOAD_BEST_PRACTICES_PDF_EXPERIENCE)
  events.Flowcode_Code_Download_Downloaded_Best_Practices_PDF()
}

export const buildForm = (
  batch: string,
  transparent: boolean,
  template: string,
  logo: File | null | undefined,
  callToAction: string | undefined,
  headline: string | undefined
): FormData => {
  const formData = new FormData()
  formData.append('template_id', template)
  formData.append('flowcode', batch)
  formData.append('transparent', transparent.toString())
  if (logo) formData.append('logo', logo)
  if (callToAction) formData.append('action_text', callToAction)
  if (headline) formData.append('headline_text', headline)

  return formData
}

export const emailEducationTemplate = async ({
  email,
  batch,
  transparent,
  template,
  logo,
  callToAction,
  headline
}: EmailEducationTemplateType): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const formData = buildForm(batch, transparent, template, logo, callToAction, headline)

      fetch(`${config.uri}/v2/flowcode/education-template/email/${email}`, {
        body: formData,
        method: 'POST',
        headers: {
          Accept: 'application/json'
        }
      }).then((res: Response) => {
        if (res.status !== 200) {
          reject(res.status)
        }
        resolve()
      })
    } catch (e) {
      reject(e)
    }
  })
}

export const downloadEducationTemplate = async ({
  templateFileType = EducationTemplateDownloadOptionMimeType.PDF,
  filename = 'template',
  batch,
  transparent,
  template,
  logo,
  callToAction,
  headline
}: DownloadEducationTemplateType): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const formData = buildForm(batch, transparent, template, logo, callToAction, headline)

      fetch(`${config.uri}/v2/flowcode/education-template/embed`, {
        body: formData,
        method: 'POST',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(response => response.blob())
        .then(async blob => {
          await fileReaderDownload(filename, templateFileType, blob)
          resolve()
        })
        .catch(e => reject(e))
    } catch (e) {
      reject(e)
    }
  })
}

export const downloadFolderInfoCsv = async (
  { folderId, folderName = 'MyFlowcodes', dispatch }: DownloadFolderInfoCsvProps,
  token: string | undefined
): Promise<void> => {
  try {
    dispatch(toggleFolderLinksIsDownloadInProgress())
    const response = await fetch(
      `${config.uri}/v2/flowcode/directory/${folderId}/download-info-csv`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    )
    if (response.status >= 400 && response.status < 600) {
      throw new Error()
    }
    const blob = await response.blob()
    await fileReaderDownload(folderName, FolderLinksDownloadOptionMimeType.CSV, blob)
    dispatch(toggleFolderLinksIsDownloadInProgress())
    dispatch(setFolderLinksDownloadJustOccurred())
    trackHeapEvent('Folder Urls Csv Download', {
      folderId
    })
    setTimeout(() => {
      dispatch(resetFolderLinksDownloadJustOccurred())
    }, 5000)
  } catch {
    dispatch(toggleFolderLinksIsDownloadInProgress())
    sendErrorNotification('Error Downloading Folder Links Csv')
  }
}

export const sendSTOEmailBatch = async (
  variables: STOEmailRequestType
): Promise<{
  createCollectibleBatch: {
    batch: { id: string }
    link: { shorturl: string; redirectValue: string }
  }
}> => {
  return gqlFetcher(SEND_STO_EMAIL_MUTATION, variables)
}

export const createFlowcode = async (
  variables: CreateFlowcodeRequestType,
  token?: string
): Promise<{
  createBatch: { ok: boolean; link: { shorturl: string; id: string }; batch: { id: string } }
}> => {
  return gqlFetcher(CREATE_FLOWCODE_MUTATION, variables, false, token)
}

export const transferBatch = async (
  variables: TransferBatchRequestType,
  token: string
): Promise<{ transferBatch: { ok: boolean; batch: { id: string } } }> => {
  return gqlFetcher(TRANSFER_BATCH_MUTATION, variables, false, token)
}

export const createFlowcodeFromURL = async (
  variables: CreateFlowcodeFromURLRequestType
): Promise<{
  autoDesignsFromUrl: { ids: string[]; configuration: string }[]
}> => {
  return gqlFetcher(CREATE_FLOWCODE_FROM_URL_QUERY, variables)
}

export type AutoDesignsFromBrandDataResponse = {
  autoDesignsFromBrandData: { ids: string[]; configuration: string }[]
}

export const getAutoDesignsFromBrandData = async (
  variables: QueryAutoDesignsFromBrandDataArgs
): Promise<AutoDesignsFromBrandDataResponse> => {
  return gqlFetcher(AUTO_DESIGNS_BRAND_DATA_QUERY, variables)
}

export const createCollectibleBatch = async (
  variables: CreateCollectibleBatchRequestType
): Promise<{
  createCollectibleBatch: { link: { shorturl: string; id: string }; batch: { id: string } }
}> => {
  return gqlFetcher(CREATE_COLLECTIBLE_BATCH_MUTATION, variables)
}

export const createBatchRule = async (
  variables: CreateBatchRuleRequestType
): Promise<{
  createBatchRule: { ok: boolean }
}> => {
  return gqlFetcher(CREATE_BATCH_RULE_MUTATION, variables)
}

export const updateBatchRule = async (
  variables: CreateBatchRuleRequestType,
  token: string | undefined
): Promise<{
  updateBatchRule: { ok: boolean }
}> => {
  return gqlFetcher(UPDATE_BATCH_RULE_MUTATION, variables, false, token)
}

export const deleteBatchRules = async (
  variables: MutationDeleteBatchRulesArgs
): Promise<{
  deleteBatchRules: { ok: boolean }
}> => {
  return gqlFetcher(DELETE_BATCH_RULES_MUTATION, variables)
}

export const saveFlowcodeTemplate = async (variables: {
  input: BatchDesignTemplateInputType
}): Promise<{
  createBatchDesignTemplate: { ok: boolean }
}> => {
  return gqlFetcher(CREATE_SAVE_TEMPLATE_MUTATION, variables)
}

export const getEncodedData = ({
  url,
  shortUrl,
  defaultValue = DEFAULT_FLOWCODE_DESTINATION,
  id
}: EncodedDataType): string => {
  if (url && isStaticRedirectType(id)) return url
  if (shortUrl) return shortUrl
  return url || defaultValue
}

export const getFlowcodeId = (shortUrl: string): string => {
  let flowcodeId = ''
  if (shortUrl && shortUrl.indexOf('://') !== -1) {
    const index = shortUrl.lastIndexOf('/')
    flowcodeId = shortUrl.substring(index + 1)
  }
  return flowcodeId
}

// TODO: extract out file upload logic
export const createFlowcodeFromFile = async (
  {
    data,
    studioConfigId,
    createBatch = true,
    nickname,
    assetType,
    staticCodeSelected,
    partialConfigIds = [],
    redirectDomain,
    assetLabels = []
  }: {
    data: File
    studioConfigId?: string
    createBatch?: boolean
    nickname?: string
    assetType: string
    staticCodeSelected?: boolean
    partialConfigIds?: string[]
    redirectDomain?: string
    assetLabels?: MutationCreateBatchArgs['assetLabels']
  },
  token: string | undefined
): Promise<{
  short_url: string
  file: string
  batch_id: string
}> => {
  const formData = new FormData()
  const anonymousSessionId = cookies.get(ANONYMOUS_SESSION_ID)
  formData.append('file', data)
  formData.append('asset_type', assetType)
  formData.append('asset_name', (data as File).name)
  formData.append('create_batch', `${createBatch}`)
  if (redirectDomain) formData.append('redirect_domain', redirectDomain)
  partialConfigIds.forEach(partialId => {
    formData.append('studio_config_partial_ids', partialId)
  })
  assetLabels?.forEach(assetLabel => {
    if (assetLabel) formData.append('asset_labels', assetLabel)
  })
  if (studioConfigId) formData.append('config_id', studioConfigId)
  if (nickname) formData.append('nickname', nickname)
  if (staticCodeSelected) formData.append('is_static_code', `${staticCodeSelected}`)
  const res = await fetch(`${config.uri}/v1/file`, {
    body: formData,
    credentials: 'include',
    method: 'POST',
    headers: {
      ...(!!token && { authorization: `Bearer ${token}` }),
      ...(!!anonymousSessionId && { AnonymousSessionId: anonymousSessionId })
    }
  })
  if (res.status !== 201) {
    const errorResponse = await res.json()
    if (Array.isArray(errorResponse)) {
      //check if the server error is related to unsafe files.  Not ideal but following the pattern of defining error types on the client rather than the server -EO
      if (errorResponse[0]?.includes('unsafe')) {
        throw UNSAFE_FILE_UPLOAD_ERROR
      }
    } else if (errorResponse?.error?.code && errorResponse?.error?.message) {
      throw errorResponse.error
    } else {
      throw new Error('error uploading file')
    }
  }
  return res.json()
}

export const uploadExternalCenterImageFile = async ({
  fileData,
  assetType
}: {
  fileData: File
  assetType: string
}): Promise<{
  file: string
}> => {
  const formData = new FormData()
  const anonSessionId = cookies.get(ANONYMOUS_SESSION_ID)
  formData.append('file', fileData)
  formData.append('asset_type', assetType)
  formData.append('asset_name', fileData.name)

  const res = await fetch(`${config.uri}/v1/file`, {
    body: formData,
    method: 'POST',
    headers: {
      ...(!!anonSessionId && { AnonymousSessionId: anonSessionId })
    }
  })

  if (res.status !== 201) throw new Error('error uploading center image')
  return res.json()
}

export const sendSTOEmail = async ({
  sendEmail
}: STOEmailRequestType): Promise<CreateBatchResponseType> => {
  const data = await sendSTOEmailBatch({
    sendEmail
  })
  return {
    batchId: data.createCollectibleBatch.batch.id,
    shortUrl: data.createCollectibleBatch.link.shorturl
  }
}

export const createCollectibleBatchCode = async ({
  nickname = '',
  partialIds,
  studioConfigId,
  batchId
}: CreateCollectibleBatchRequestType): Promise<CreateBatchResponseType> => {
  const data = await createCollectibleBatch({
    nickname,
    partialIds,
    studioConfigId,
    batchId
  })
  return {
    batchId: data.createCollectibleBatch.batch.id,
    shortUrl: data.createCollectibleBatch.link.shorturl
  }
}

export const isFlowcodeCustomizable = (flowcodeTemplate: FlowcodeTemplateType | null): boolean => {
  if (!flowcodeTemplate) return false
  return (
    flowcodeTemplate.isCustomizable ||
    (!!flowcodeTemplate?.options && !Object.values(flowcodeTemplate?.options).length)
  )
}

// studio

export const createStudioConfig = async (
  variables: CreateStudioConfigVariablesType
): Promise<{ createStudioConfig: { ok: boolean; studioConfig: { id: string } } }> => {
  const vars = { ...variables, configuration: JSON.stringify(variables.configuration) }
  return gqlFetcher(CREATE_STUDIO_CONFIG_MUTATION, vars)
}

export const getNewStudioConfigId = async (
  {
    configuration,
    centerImage,
    centerImagePreviewUrl,
    setCenterImagePreviewUrl,
    isCustomizable,
    name,
    isAutoDesign
  }: GetNewStudioConfigIdType,
  token: string | undefined
): Promise<string | undefined> => {
  if (!configuration || !isCustomizable) return undefined

  if (centerImage) {
    const logoImageUrl = (
      await createFlowcodeFromFile(
        {
          data: centerImage as File,
          createBatch: false,
          assetType: AssetTypes.EXTERNAL_CENTER_IMAGE
        },
        token
      )
    ).file
    if (logoImageUrl?.startsWith('blob')) {
      console.error('Blob center image failed to upload', { configuration })
      throw new Error('Error creating sudio config: Could not convert center image blob to file')
    }
    configuration.logoImageUrl = logoImageUrl
    if (centerImagePreviewUrl) URL.revokeObjectURL(centerImagePreviewUrl)
    setCenterImagePreviewUrl && setCenterImagePreviewUrl(logoImageUrl)
  }
  const source = isAutoDesign ? StudioConfigSourceType.AUTODESIGN : StudioConfigSourceType.DIY
  const createStudioConfigData = await createStudioConfig({
    configuration,
    name: name ? `${source}_${name}_${uuidv4()}` : `${source}_${uuidv4()}`,
    source
  })
  if (!createStudioConfigData.createStudioConfig.ok) throw new Error('error creating studio config')
  return (
    getIdFromEncodedString(createStudioConfigData.createStudioConfig.studioConfig.id) || undefined
  )
}

export const getNewStudioConfigIdAndUploadedCenterImageUrl = async (
  { configuration, centerImage, isCustomizable, name, isAutoDesign }: GetNewStudioConfigIdType,
  token: string | undefined
): Promise<{
  studioConfigId: string
  centerImageUrl: string
} | null> => {
  if (!configuration || !isCustomizable) return null
  const newConfig = { ...configuration }
  if (centerImage) {
    const logoImageUrl = (
      await createFlowcodeFromFile(
        {
          data: centerImage as File,
          createBatch: false,
          assetType: AssetTypes.EXTERNAL_CENTER_IMAGE
        },
        token
      )
    ).file
    newConfig.logoImageUrl = logoImageUrl
    if (configuration.logoImageUrl) URL.revokeObjectURL(configuration.logoImageUrl)
  }
  const createStudioConfigData = await createStudioConfig({
    configuration: newConfig,
    name: name ? `DIY_${name}_${uuidv4()}` : `DIY_${uuidv4()}`,
    source: isAutoDesign ? StudioConfigSourceType.AUTODESIGN : StudioConfigSourceType.DIY
  })
  if (!createStudioConfigData.createStudioConfig.ok) throw new Error('error creating studio config')
  return {
    studioConfigId: getIdFromEncodedString(
      createStudioConfigData.createStudioConfig.studioConfig.id
    ),
    centerImageUrl: newConfig.logoImageUrl ?? ''
  }
}

// Resize center image based on some max dimension, this can be renamed if it has a more generic use case
export const getFlowcodeLogoDimensions = async (
  src: File,
  maxDimensionSize = 30
): Promise<ImageDimensionType> => {
  const { width, height } = await getImageDimensions(src)
  const max = Math.max(width, height)
  const min = Math.min(width, height)
  const proportionalDimension = (maxDimensionSize * min) / max
  const isWidthLargest = max === width
  return {
    height: isWidthLargest ? proportionalDimension : maxDimensionSize,
    width: isWidthLargest ? maxDimensionSize : proportionalDimension
  }
}

// change to a pure function?
export const generateRandomFlowcodeUrl = (prefix = DEFAULT_FLOWCODE_DESTINATION_PREFIX): string => {
  return prefix.concat(generateRandomString())
}

export const copyToClipboard = async (
  value: string,
  successMessage = 'Code link copied.'
): Promise<void> => {
  return navigator.clipboard
    .writeText(value)
    .then(() => {
      sendSuccessNotification(successMessage)
    })
    .catch(e => {
      console.error('error copying to clipboard', e.message)
      sendErrorNotification('error copying to clipboard')
    })
}

export interface UpdateFlowcodeResponse {
  updateBatchLink: { ok: boolean; batch: { id: string } }
}

export const updateFlowcode = async (
  variables: UpdateFlowcodeVariablesType,
  token?: string
): Promise<UpdateFlowcodeResponse> => {
  return gqlFetcher(UPDATE_FLOWCODE_MUTATION, variables, false, token)
}

export const updateBatchDirectory = async (
  variables: UpdateBatchDirectoryVariablesType,
  token?: string
): Promise<{ updateBatchDirectory: { ok: boolean } }> => {
  return gqlFetcher(UPDATE_BATCH_DIRECTORY_MUTATION, variables, false, token)
}

export interface BulkUpdateBatchDirectoryResponse {
  bulkUpdateBatchDirectory: { ok: boolean }
}

export const bulkUpdateBatchDirectory = async (
  variables: BulkUpdateBatchDirectoryVariablesType
): Promise<BulkUpdateBatchDirectoryResponse> => {
  return gqlFetcher(BULK_UPDATE_BATCH_DIRECTORY_MUTATION, variables)
}

/**
 * Update batch with a file
 *
 */
export const updateBatchWithFile = async (
  {
    file,
    batchId
  }: {
    file: File
    batchId: string
  },
  token?: string | undefined
): Promise<{
  short_url: string
  file: string
  batch_id: string
}> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('asset_type', LandingPageDestination.FILE)
  formData.append('asset_name', file.name)
  formData.append('batch', batchId)
  const res = await fetch(`${config.uri}/v2/flowcode/batch/update-file`, {
    body: formData,
    method: 'POST',
    ...(token && {
      headers: {
        authorization: `Bearer ${token}`
      }
    }),
    credentials: 'include'
  })
  if (res.status !== 201) {
    let errorMessage = await res.json()
    if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage[0]
      //check if the server error is related to unsafe files.  Not ideal but following the pattern of defining error types on the client rather than the server -EO
      if (errorMessage?.includes('unsafe')) {
        throw UNSAFE_FILE_UPLOAD_ERROR
      }
    }
    throw new Error('Error updating Flowcode')
  }

  return res.json()
}

export const getInitialDestinationType = (
  option: string | string[] | undefined
): LandingPageDestinationType => {
  const destination = LANDING_PAGE_DESTINATIONS.find(
    destination => destination.id.toUpperCase() === (option as string)?.toUpperCase()
  )
  return destination || LANDING_PAGE_DESTINATIONS[0]
}

export const addFlowcodeToFolder = async (
  batchId: string,
  directoryId: string,
  token?: string
): Promise<void> => {
  try {
    const updateBatchDirectoryData = await updateBatchDirectory(
      {
        batchId,
        removeDirectory: false,
        moveToDirectory: directoryId
      },
      token
    )
    if (!updateBatchDirectoryData.updateBatchDirectory.ok)
      throw new Error('error moving flowcode to folder')
  } catch (e) {
    console.error(`error adding batch: ${batchId} to directory: ${directoryId}`, e.message)
    const { message } = getGqlError(e, false)
    sendErrorNotification('Error adding flowcode to folder. ' + message)
  }
}

export const validateBulkCreationCsvFile = async (
  {
    data
  }: {
    data: File
  },
  token: string | undefined
): Promise<ValidateFlowcodeBulkResponseType> => {
  const formData = new FormData()
  formData.append('csv_file', data)
  const res = await fetch(`${config.uri}/v2/flowcode/batch/bulk-csv-validation`, {
    body: formData,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (res.status !== 200) throw new Error('error validating create bulk flowcode csv file.')
  return res.json()
}

export const createFlowcodeBulk = async (
  {
    folderName,
    createFlowcodeCsvFile,
    studioConfigId,
    staticCodeSelected,
    partialConfigIds,
    redirectDomain,
    parentDirectoryId
  }: CreateFlowcodeBulkProps,
  token: string | undefined
): Promise<CreateFlowcodeBulkResponseType> => {
  if (folderName && createFlowcodeCsvFile && studioConfigId) {
    const formData = new FormData()
    formData.append('csv_file', createFlowcodeCsvFile)
    formData.append('folder_name', folderName)
    formData.append('studio_config_id', studioConfigId)
    formData.append('is_static_code', `${staticCodeSelected}`)
    if (redirectDomain) formData.append('redirect_domain', redirectDomain)
    if (parentDirectoryId) formData.append('parent_directory_id', parentDirectoryId)
    partialConfigIds?.forEach(id => {
      formData.append('studio_config_partial_ids', id)
    })
    const res = await fetch(`${config.uri}/v2/flowcode/batch/bulk-csv-creation`, {
      body: formData,
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    const respJSON = await res.json()
    if (res.status !== 200) {
      throw new Error(respJSON.detail || 'Error creating flowcodes.')
    }
    return respJSON
  } else {
    throw new Error('Error creating flowcodes.')
  }
}

export const editFlowcodeConfig = async (
  batchId: string,
  config: FcGeneratorOptions,
  token: string | undefined,
  partialIds: string[] = [],
  isAutoDesign = false
): Promise<{ editBatchDesign: EditBatchDesign }> => {
  const configuration = JSON.stringify(config)
  return gqlFetcher(
    EDIT_BATCH_CONFIG_MUTATION,
    {
      batchId,
      newConfig: configuration,
      partialIds,
      isAutoDesign
    },
    false,
    token
  )
}

export const switchFlowcodeConfig = async (
  batchId: string,
  newConfigId: string,
  token: string | undefined,
  partialIds: string[] = []
): Promise<{ switchBatchDesign: SwitchBatchDesign }> => {
  return gqlFetcher(SWITCH_BATCH_MUTATION, { batchId, newConfigId, partialIds }, false, token)
}

export const updateImageDimensions = async (
  image: File | null,
  isMounted: boolean,
  setLogoHeight: (height: number) => void,
  setLogoWidth: (width: number) => void
): Promise<void> => {
  if (!image) return
  try {
    const { width, height } = await getFlowcodeLogoDimensions(image)

    if (isMounted) {
      setLogoHeight(height)
      setLogoWidth(width)
    }
  } catch (e) {
    console.error('error setting center image dimensions', e.message)
    if (isMounted) {
      setLogoHeight(DEFAULT_FLOWCODE_LOGO_DIMENSIONS.HEIGHT)
      setLogoWidth(DEFAULT_FLOWCODE_LOGO_DIMENSIONS.WIDTH)
    }
  }
}

export const handleDownloadFlowcodeHelper = async ({
  setDownloadingFlowcode,
  flowcodeOptions,
  fileType,
  isMounted,
  onSuccess
}: HandleDownloadFlowcodeType): Promise<void> => {
  try {
    setDownloadingFlowcode(true)
    const flowcodeId = getFlowcodeId(flowcodeOptions.data ?? '')
    await downloadFlowcode({
      targetFileType: fileType,
      flowcodeOptions: flowcodeOptions,
      flowcodeId
    })
    if (onSuccess) onSuccess()
  } catch (e) {
    console.error('error downloading flowcode', e.message)
    sendErrorNotification('Error downloading Flowcode. Please try again.')
  } finally {
    if (isMounted) setDownloadingFlowcode(false)
  }
}

/**
 *
 * Used in the code management page. It encodes the flowcode's redirect value if the redirect type is event or wifi.
 *
 *
 */
export const getFormattedFlowcodeOptions = (flowcodeData: FlowcodeType): FcGeneratorOptions => {
  const options: FcGeneratorOptions = flowcodeData.studioConfig?.configuration
    ? JSON.parse(flowcodeData.studioConfig.configuration)
    : null

  const shortUrl = flowcodeData?.activeLink?.shorturl
  const redirectValue = flowcodeData?.activeLink?.redirectValue
  const isStatic = isStaticCode(flowcodeData)
  const encodedData = isStatic ? redirectValue : shortUrl

  return {
    ...options,
    data: encodedData || DEFAULT_FLOWCODE_DESTINATION_LOGGED_IN
  }
}

export const collectBatch = async (
  variables: CollectBatchVariablesType
): Promise<CollectBatchResponseType> => {
  return gqlFetcher(COLLECT_BATCH_MUTATION, variables)
}

export const presetCollectBatch = async (
  variables: CollectBatchVariablesType
): Promise<PresetCollectBatchResponseType> => {
  return gqlFetcher(PRESET_COLLECT_BATCH_MUTATION, variables)
}

export const handleCollectBatch = async ({
  batchId,
  flowcodeId,
  showErrorNotification = true
}: HandleCollectBatchArgs): Promise<void> => {
  try {
    const collectBatchArgs = flowcodeId ? { flowcodeId } : { batchId }
    const data = await collectBatch(collectBatchArgs)
    if (!data.collectBatch.ok) throw new Error('Error collecting flowcode')
  } catch (e) {
    if (showErrorNotification) sendErrorNotification(e.message)
    console.error('collectBatch', e)
  }
}

interface FlowcodeImageUrlType {
  options: FcGeneratorOptions | string
  density?: string
  imageType?: FlowcodeDownloadOptionMimeType
}

export const getFlowcodeImageUrl = ({
  options,
  density = DEFAULT_DPI,
  imageType = FlowcodeDownloadOptionMimeType.PNG
}: FlowcodeImageUrlType): string => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options))
  return `${getFlowcodeGeneratorRootUrl()}/v1/flowcode?opts=${encodedOptions}&density=${density}&imageType=${imageType}`
}

export const validFileTypes = (destination: LandingPageDestination): string => {
  if (destination === LandingPageDestination.PDF) return 'application/pdf'
  if (destination === LandingPageDestination.IMAGE) return 'image/*'
  return 'application/pdf, image/*, audio/*, video/*'
}

export const getFilenameWithExtension = async (
  filename: string,
  mimeType: string
): Promise<string> => {
  try {
    const lookup = await import('mime-types').then(mod => mod.lookup)
    if (lookup(filename)) return filename
    const extension = await import('mime-types')
      .then(mod => mod.extension)
      .then(getExtension => getExtension(mimeType))
    if (!extension) throw new Error('extension not found')
    return `${filename}.${extension}`
  } catch (e) {
    console.error(e)
    return filename
  }
}

export const loadImage = async (url: string, filename = 'logo'): Promise<File> => {
  const res = await fetch(url, {
    method: 'GET'
  })
  const type = res.headers.get('content-type') ?? ''
  const updatedFilename = await getFilenameWithExtension(filename, type)
  return new File([await res.blob()], updatedFilename, {
    type
  })
}

export const getBatchIdFromFlowcodeId = (flowcodeId: string): string => {
  return flowcodeId.slice(0, -4)
}

/**
 * Given a dataPatternColor and a backgroundColor return the one that is not the default.
 * Default dataPatternColor is black and backgroundColor is white. If we ever let the
 * user pick both the data and background color this function will be unnecessary because
 * both colors will be custom.
 *
 * This function is used to determine what color the user picked now that sc-42682
 * introduces a HEX picker and the data and background colors get swaped based on contrast.
 *
 * @see {@link https://app.shortcut.com/flowcode/story/42682/hex-on-code-functionality}
 */
export const getColorPickedByUser = (dataPatternColor: string, backgroundColor: string): string => {
  let customColor = '#000000'

  if (dataPatternColor.toUpperCase() !== '#000000' && dataPatternColor.toUpperCase() !== '#000')
    customColor = dataPatternColor
  else if (backgroundColor.toUpperCase() !== '#FFFFFF' && backgroundColor.toUpperCase() !== '#FFF')
    customColor = backgroundColor

  return customColor.toUpperCase()
}
